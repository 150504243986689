import React, { useState } from "react";
import { Button } from "antd";
import { Container, Box } from "@lib/Layout";
import EmailTemplate from "./EmailTemplate/EmailTemplate";
import EmailCreateRule from "./EmailCreateRule/EmailCreateRule";
import Schedule from "./Schedule/Schedule";

const TripEmailCampaign = () => {
  const [activeTab, setActiveTab] = useState("template");

  const renderContent = () => {
    switch (activeTab) {
      case "template":
        return <EmailTemplate />;
      case "rule":
        return <EmailCreateRule />;
      case "schedule":
        return <Schedule />;
      default:
        return <EmailTemplate />;
    }
  };

  return (
    <Container className="gap-2 bg-sky-50 p-3">
      <Box
        style={{
          boxShadow:
            "rgba(0, 0, 0, 0.1) 0px 0px 5px 0px, rgba(0, 0, 0, 0.1) 0px 0px 1px 0px",
        }}
        className="bg-white p-2 rounded-md"
      >
        <Container alignBox="row" align="vertical" className="gap-4">
          <Box>
            <Button
              type={activeTab === "template" ? "primary" : "default"}
              onClick={() => setActiveTab("template")}
            >
              Templates
            </Button>
          </Box>
          <Box>
            <Button
              type={activeTab === "rule" ? "primary" : "default"}
              onClick={() => setActiveTab("rule")}
            >
              Create Rule
            </Button>
          </Box>
          <Box>
            <Button
              type={activeTab === "schedule" ? "primary" : "default"}
              onClick={() => setActiveTab("schedule")}
            >
              Schedule
            </Button>
          </Box>
        </Container>
      </Box>
      <Box flexible scroll="vertical" className="rounded-md">
        {renderContent()}
      </Box>
    </Container>
  );
};

export default TripEmailCampaign;
