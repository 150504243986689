import React from "react";

const Button = ({
  color = "blue",
  children,
  onClick,
  className,
  style,
  disabled,
}) => {
  const baseStyle =
    "focus:outline-none font-medium rounded-lg text-sm px-3 py-1 transition duration-200 ";

  const colors = {
    blue: "text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 ",
    gray: "text-white bg-gray-800 hover:bg-gray-900 focus:ring-4 focus:ring-gray-300 ",
    light:
      "text-gray-900 bg-white border border-gray-300 hover:bg-gray-100 focus:ring-4 focus:ring-gray-100 ",
    green:
      "text-white bg-green-700 hover:bg-green-800 focus:ring-4 focus:ring-green-300 ",
    red: "text-white bg-red-700 hover:bg-red-800 focus:ring-4 focus:ring-red-300 ",
    yellow:
      "text-white bg-yellow-400 hover:bg-yellow-500 focus:ring-4 focus:ring-yellow-300 ",
    purple:
      "text-white bg-purple-700 hover:bg-purple-800 focus:ring-4 focus:ring-purple-300 ",
  };

  const disabledStyle = "opacity-40 cursor-not-allowed  ";

  const colorStyle = colors[color] || colors.red;

  return (
    <button
      style={style}
      type="button"
      className={`${baseStyle} ${colorStyle} ${className} ${
        disabled ? disabledStyle : ""
      }`}
      onClick={!disabled ? onClick : undefined}
      disabled={disabled}
    >
      {children}
    </button>
  );
};

export default Button;
