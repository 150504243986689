import React, { useEffect, useState } from "react";
import { Container, Box } from "@lib/Layout";
import useFetchRuleById from "../../../api/useFetchRuleById";
import SqlCodePreview from "@components/SqlCodePreview/SqlCodePreview";

const PreviewRule = () => {
  const [ruleId, setRuleId] = useState(null);

  useEffect(() => {
    const scheduledData = localStorage.getItem("scheduledData");
    const editScheduleData = localStorage.getItem("EditScheduledData");

    try {
      // Parse data only if it's a string
      const parsedScheduledData = scheduledData
        ? typeof scheduledData === "string"
          ? JSON.parse(scheduledData)
          : scheduledData
        : null;
      const parsedEditScheduleData = editScheduleData
        ? typeof editScheduleData === "string"
          ? JSON.parse(editScheduleData)
          : editScheduleData
        : null;

      if (parsedScheduledData || parsedEditScheduleData) {
        const { rule } = parsedScheduledData || parsedEditScheduleData;
        setRuleId(rule);
      }
    } catch (error) {
      console.error("Error parsing scheduled data:", error);
    }
  }, []);

  const { rule, loading, error } = useFetchRuleById(ruleId);
  console.log(rule);

  return (
    <Container>
      <Box
        style={{
          width: "500px",
          height: "450px",
          boxShadow: "rgba(0, 0, 0, 0.1) 0px 4px 12px",
        }}
        className="rounded-lg"
      >
        {loading && (
          <div role="status" class="max-w-md p-4 space-y-4 animate-pulse ">
            <div class="flex items-center justify-between">
              <div>
                <div class="h-2.5 bg-gray-300 rounded-full dark:bg-gray-600 w-24 mb-2.5"></div>
                <div class="w-32 h-2 bg-gray-200 rounded-full dark:bg-gray-700"></div>
              </div>
              <div class="h-2.5 bg-gray-300 rounded-full dark:bg-gray-700 w-12"></div>
            </div>
            <div class="flex items-center justify-between pt-4">
              <div>
                <div class="h-2.5 bg-gray-300 rounded-full dark:bg-gray-600 w-24 mb-2.5"></div>
                <div class="w-32 h-2 bg-gray-200 rounded-full dark:bg-gray-700"></div>
              </div>
              <div class="h-2.5 bg-gray-300 rounded-full dark:bg-gray-700 w-12"></div>
            </div>
            <div class="flex items-center justify-between pt-4">
              <div>
                <div class="h-2.5 bg-gray-300 rounded-full dark:bg-gray-600 w-24 mb-2.5"></div>
                <div class="w-32 h-2 bg-gray-200 rounded-full dark:bg-gray-700"></div>
              </div>
              <div class="h-2.5 bg-gray-300 rounded-full dark:bg-gray-700 w-12"></div>
            </div>
            <div class="flex items-center justify-between pt-4">
              <div>
                <div class="h-2.5 bg-gray-300 rounded-full dark:bg-gray-600 w-24 mb-2.5"></div>
                <div class="w-32 h-2 bg-gray-200 rounded-full dark:bg-gray-700"></div>
              </div>
              <div class="h-2.5 bg-gray-300 rounded-full dark:bg-gray-700 w-12"></div>
            </div>
            <div class="flex items-center justify-between pt-4">
              <div>
                <div class="h-2.5 bg-gray-300 rounded-full dark:bg-gray-600 w-24 mb-2.5"></div>
                <div class="w-32 h-2 bg-gray-200 rounded-full dark:bg-gray-700"></div>
              </div>
              <div class="h-2.5 bg-gray-300 rounded-full dark:bg-gray-700 w-12"></div>
            </div>
            <div class="flex items-center justify-between pt-4">
              <div>
                <div class="h-2.5 bg-gray-300 rounded-full dark:bg-gray-600 w-24 mb-2.5"></div>
                <div class="w-32 h-2 bg-gray-200 rounded-full dark:bg-gray-700"></div>
              </div>
              <div class="h-2.5 bg-gray-300 rounded-full dark:bg-gray-700 w-12"></div>
            </div>
            <div class="flex items-center justify-between pt-4">
              <div>
                <div class="h-2.5 bg-gray-300 rounded-full dark:bg-gray-600 w-24 mb-2.5"></div>
                <div class="w-32 h-2 bg-gray-200 rounded-full dark:bg-gray-700"></div>
              </div>
              <div class="h-2.5 bg-gray-300 rounded-full dark:bg-gray-700 w-12"></div>
            </div>

            <span class="sr-only">Loading...</span>
          </div>
        )}
        {error && (
          <div className="flex items-center justify-center">
            <span>{error}</span>
          </div>
        )}
        {!loading && !error && (
          <>
            <h1 className="p-2 text-slate-700 text-lg font-semibold">
              Selected Rule
            </h1>
            <div className="px-2">
              {" "}
              <div className="bg-slate-200 p-2 text-base rounded-md">
                {rule ? (
                  <>
                    <p>
                      <span className="text-slate-600">Rule Name: </span>{" "}
                      {rule.RuleName}
                    </p>
                    <p className="flex items-center">
                      <span className="text-slate-600">Count: </span>
                      <span className="text-lg text-blue-700">
                        {rule.Counts ?? "0"}
                      </span>
                    </p>
                    <p>
                      <span className="text-slate-600">Status:</span>{" "}
                      {rule.Status}
                    </p>
                  </>
                ) : (
                  <p>No Data!</p>
                )}
              </div>
            </div>
            <h1 className="p-2 text-slate-700 text-lg font-semibold">
              Rule Query
            </h1>

            <Box flexible scroll="vertical" className="px-2 ">
              {rule ? (
                <SqlCodePreview sqlString={rule.RuleCriteria} />
              ) : (
                "Rule Not Available"
              )}
            </Box>
          </>
        )}
      </Box>
    </Container>
  );
};

export default PreviewRule;
