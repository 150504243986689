import React, {
  createContext,
  useContext,
  useEffect,
  useState,
  useCallback,
} from "react";
import { useGoogleLogin } from "@react-oauth/google";
import { message } from "antd";
import moment from "moment";

const GmailContext = createContext();

export const useGmail = () => {
  return useContext(GmailContext);
};

const GmailProvider = ({ children }) => {
  const [token, setToken] = useState(localStorage.getItem("g_token") || null);
  const [refreshToken, setRefreshToken] = useState(
    localStorage.getItem("g_refresh_token") || null
  );
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [emailLoading, setEmailLoading] = useState(false);
  const [emailError, setEmailError] = useState(null);
  const [emails, setEmails] = useState([]); // State to hold fetched emails

  const login = useGoogleLogin({
    flow: "auth-code",
    onSuccess: async (response) => {
      setLoading(true);
      const { code } = response;

      try {
        const res = await fetch(
          "https://usfarmdata.org/fastapi/loginbygoogle",
          {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({ code: code }),
          }
        );

        const data = await res.json();
        if (data.access_token && data.refresh_token) {
          setToken(data.access_token);
          setRefreshToken(data.refresh_token);
          const expiresIn = data.expires_in * 1000;
          localStorage.setItem("g_token", data.access_token);
          localStorage.setItem("g_refresh_token", data.refresh_token);
          localStorage.setItem("token_expiry_time", Date.now() + expiresIn);
          message.success("Logged in successfully");
        } else {
          message.error("Token exchange failed");
          setError("Token exchange failed");
        }
      } catch (error) {
        console.error("Error during token exchange:", error);
        message.error("Login failed. Please try again.");
        setError("Login failed. Please try again.");
      } finally {
        setLoading(false);
      }
    },
    onError: () => {
      message.error("Login failed. Please try again.");
      setError("Login failed. Please try again.");
    },
    scope:
      "openid profile email https://www.googleapis.com/auth/gmail.readonly",
  });

  const handleLogout = useCallback(() => {
    setToken(null);
    setRefreshToken(null);
    localStorage.removeItem("g_token");
    localStorage.removeItem("g_refresh_token");
    localStorage.removeItem("token_expiry_time");
  }, []);

  const refreshAccessToken = useCallback(async () => {
    if (!refreshToken) {
      handleLogout();
      return;
    }

    try {
      const res = await fetch("https://usfarmdata.org/fastapi/refresh_token", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ refreshtoken: refreshToken }),
      });

      const data = await res.json();
      if (data.access_token) {
        setToken(data.access_token);
        const expiresIn = data.expires_in * 1000;
        localStorage.setItem("g_token", data.access_token);
        localStorage.setItem("token_expiry_time", Date.now() + expiresIn);
      } else {
        handleLogout();
      }
    } catch (error) {
      console.error("Error refreshing token:", error);
      handleLogout();
    }
  }, [refreshToken]);

  const isTokenExpired = useCallback(() => {
    const expiryTime = localStorage.getItem("token_expiry_time");
    return Date.now() >= expiryTime;
  }, []);

  const checkToken = useCallback(async () => {
    if (isTokenExpired()) {
      await refreshAccessToken();
    }
  }, [isTokenExpired, refreshAccessToken]);

  useEffect(() => {
    const intervalId = setInterval(refreshAccessToken, 3600000); // 1 hour
    return () => clearInterval(intervalId);
  }, [refreshAccessToken]);

  useEffect(() => {
    const intervalId = setInterval(checkToken, 60000); // 1 minute
    return () => clearInterval(intervalId);
  }, [checkToken]);

  /**
   * Function to extract email body and attachment metadata from email parts
   */
  const extractParts = useCallback((parts) => {
    let attachments = [];
    let textPlain = "";
    let textHtml = "";

    const traverseParts = (parts) => {
      for (const part of parts) {
        if (part.parts) {
          traverseParts(part.parts);
        } else {
          const mimeType = part.mimeType;
          const bodyData = part.body.data;

          if (mimeType === "text/plain") {
            if (bodyData) {
              const decodedBody = atob(
                bodyData.replace(/-/g, "+").replace(/_/g, "/")
              );
              textPlain += decodedBody;
            }
          } else if (mimeType === "text/html") {
            if (bodyData) {
              const decodedBody = atob(
                bodyData.replace(/-/g, "+").replace(/_/g, "/")
              );
              textHtml += decodedBody;
            }
          } else if (part.filename) {
            const attachmentId = part.body.attachmentId;
            attachments.push({
              filename: part.filename,
              mimeType: mimeType,
              attachmentId: attachmentId,
            });
          }
        }
      }
    };

    traverseParts(parts);

    const body = textHtml || textPlain; // Prefer HTML if available
    return { body, attachments };
  }, []);

  /**
   * Function to fetch attachment details on demand
   */
  const fetchAttachment = useCallback(
    async (messageId, attachment) => {
      if (!token) {
        message.error("No token available for fetching attachments.");
        return null;
      }

      try {
        const res = await fetch(
          `https://gmail.googleapis.com/gmail/v1/users/me/messages/${messageId}/attachments/${attachment.attachmentId}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        const data = await res.json();
        if (data.data) {
          const byteCharacters = atob(
            data.data.replace(/-/g, "+").replace(/_/g, "/")
          );
          const byteNumbers = new Array(byteCharacters.length);
          for (let i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
          }
          const byteArray = new Uint8Array(byteNumbers);
          const blob = new Blob([byteArray], { type: attachment.mimeType });
          const url = URL.createObjectURL(blob);
          return {
            filename: attachment.filename,
            mimeType: attachment.mimeType,
            url: url,
          };
        } else {
          message.error(`Failed to fetch attachment: ${attachment.filename}`);
          return null;
        }
      } catch (error) {
        console.error(
          `Error fetching attachment ${attachment.filename}:`,
          error
        );
        message.error(`Error fetching attachment: ${attachment.filename}`);
        return null;
      }
    },
    [token]
  );

  /**
   * Function to fetch emails based on sender and recipient
   * Attachments are not fetched here; only metadata is included
   */
  const getEmailsByFromAndTo = useCallback(
    async (from, to) => {
      setEmailLoading(true);
      setEmailError(null);
      setEmails([]); // Reset emails state

      if (!token) {
        console.error("No token available for fetching emails");
        setEmailError("No token available");
        setEmailLoading(false);
        return;
      }

      try {
        const res = await fetch(
          `https://gmail.googleapis.com/gmail/v1/users/me/messages?q=from:${encodeURIComponent(
            from
          )} to:${encodeURIComponent(to)}&maxResults=10`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        const data = await res.json();
        if (data.messages && data.messages.length > 0) {
          // Fetch each message's details without attachments
          const emailPromises = data.messages.map(async (message) => {
            const msgRes = await fetch(
              `https://gmail.googleapis.com/gmail/v1/users/me/messages/${message.id}`,
              {
                headers: {
                  Authorization: `Bearer ${token}`,
                },
              }
            );
            const msgData = await msgRes.json();
            const payload = msgData.payload;

            const headers = payload.headers;

            const subjectHeader = headers.find(
              (header) => header.name === "Subject"
            );
            const fromHeader = headers.find((header) => header.name === "From");
            const toHeader = headers.find((header) => header.name === "To");

            const subject = subjectHeader ? subjectHeader.value : "No Subject";
            const from = fromHeader ? fromHeader.value : "No Sender";
            const to = toHeader ? toHeader.value : "No Recipient";

            const dateHeader = headers.find((header) => header.name === "Date");
            let date = "No Date";
            if (dateHeader) {
              const parsedDate = moment(new Date(dateHeader.value));
              if (parsedDate.isValid()) {
                date = parsedDate.format("MMMM Do YYYY, h:mm A");
              }
            }

            const { body, attachments } = extractParts(payload.parts || []);

            return {
              id: message.id, // Add message ID for fetching attachments
              subject,
              from,
              to,
              date,
              body,
              attachments, // Only metadata; no URLs
            };
          });

          const fetchedEmails = await Promise.all(emailPromises);
          setEmails(fetchedEmails);
        } else {
          message.info("No emails found in this contact.");
        }
      } catch (error) {
        console.error("Error fetching emails:", error);
        setEmailError("Error fetching emails");
      } finally {
        setEmailLoading(false);
      }
    },
    [token, extractParts]
  );

  return (
    <GmailContext.Provider
      value={{
        token,
        refreshToken,
        login,
        handleLogout,
        loading,
        error,
        emailLoading,
        emailError,
        emails,
        getEmailsByFromAndTo,
        fetchAttachment, // Provide the fetchAttachment function
      }}
    >
      {children}
    </GmailContext.Provider>
  );
};

export default GmailProvider;
