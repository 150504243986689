import React, { useState } from "react";
import { Container, Box } from "@lib/Layout";
import { QueryBuilderAntD } from "@react-querybuilder/antd";
import { QueryBuilder, formatQuery, ValueEditor } from "react-querybuilder";
import { Button, message, Flex, Tooltip } from "antd";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import InfoIcon from "@mui/icons-material/Info";
import styled from "@emotion/styled";
import CustomModel from "@components/CustomModel/CustomModel";

const fields = [
  { name: "Firstname", label: "Firstname" },
  { name: "Lastname", label: "Lastname" },
  {
    name: "Email",
    label: "Email",
    validator: ({ value }) => /^[^@]+@[^@]+/.test(value),
  },
  { name: "Phone", label: "Phone" },
  {
    name: "State",
    label: "State",
    valueEditorType: "multiselect",
    values: [
      { name: "AL", label: "Alabama" },
      { name: "AK", label: "Alaska" },
      { name: "AZ", label: "Arizona" },
      { name: "AR", label: "Arkansas" },
      { name: "CA", label: "California" },
      { name: "CO", label: "Colorado" },
      { name: "CT", label: "Connecticut" },
      { name: "DE", label: "Delaware" },
      { name: "FL", label: "Florida" },
      { name: "GA", label: "Georgia" },
      { name: "HI", label: "Hawaii" },
      { name: "ID", label: "Idaho" },
      { name: "IL", label: "Illinois" },
      { name: "IN", label: "Indiana" },
      { name: "IA", label: "Iowa" },
      { name: "KS", label: "Kansas" },
      { name: "KY", label: "Kentucky" },
      { name: "LA", label: "Louisiana" },
      { name: "ME", label: "Maine" },
      { name: "MD", label: "Maryland" },
      { name: "MA", label: "Massachusetts" },
      { name: "MI", label: "Michigan" },
      { name: "MN", label: "Minnesota" },
      { name: "MS", label: "Mississippi" },
      { name: "MO", label: "Missouri" },
      { name: "MT", label: "Montana" },
      { name: "NE", label: "Nebraska" },
      { name: "NV", label: "Nevada" },
      { name: "NH", label: "New Hampshire" },
      { name: "NJ", label: "New Jersey" },
      { name: "NM", label: "New Mexico" },
      { name: "NY", label: "New York" },
      { name: "NC", label: "North Carolina" },
      { name: "ND", label: "North Dakota" },
      { name: "OH", label: "Ohio" },
      { name: "OK", label: "Oklahoma" },
      { name: "OR", label: "Oregon" },
      { name: "PA", label: "Pennsylvania" },
      { name: "RI", label: "Rhode Island" },
      { name: "SC", label: "South Carolina" },
      { name: "SD", label: "South Dakota" },
      { name: "TN", label: "Tennessee" },
      { name: "TX", label: "Texas" },
      { name: "UT", label: "Utah" },
      { name: "VT", label: "Vermont" },
      { name: "VA", label: "Virginia" },
      { name: "WA", label: "Washington" },
      { name: "DC", label: "Washington DC" },
      { name: "WV", label: "West Virginia" },
      { name: "WI", label: "Wisconsin" },
      { name: "WY", label: "Wyoming" },
    ],
    defaultValue: ["AL"],
    editorProps: {
      style: { width: "300px" },
    },
  },
  { name: "Title", label: "Title" },
  { name: "Zipcode", label: "Zipcode" },
];

const defaultQuery = {
  rules: [
    {
      field: "Firstname",
      operator: "=",
      value: " ",
    },
  ],
};

const Resizer = styled.div`
  width: 5px;
  cursor: col-resize;
  background-color: #a8bbc4;
  position: relative;
  border-radius: 50%;
`;

const CustomQueryBuilder = () => {
  const [query, setQuery] = useState(defaultQuery);
  const [listName, setListName] = useState("");
  const [loading, setLoading] = useState(false);
  const [filterLoading, setFilterLoading] = useState(false);
  const [liveQuery, setLiveQuery] = useState(formatQuery(defaultQuery, "sql"));
  const [parent1Width, setParent1Width] = useState(75);
  const [isDragging, setIsDragging] = useState(false);
  const [filterData, setFilterData] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  console.log("filterData", filterData);

  const handleQueryChange = (newQuery) => {
    setQuery(newQuery);
    setLiveQuery(formatQuery(newQuery, "sql"));
  };
  const sql = formatQuery(query, "sql");
  console.log(sql, "sql");

  const handleClearQuery = () => {
    setQuery({
      rules: [
        {
          field: "Firstname",
          operator: "=",
          value: " ",
        },
      ],
    });
    setLiveQuery("");
  };

  const handleListNameChange = (e) => {
    setListName(e.target.value);
  };

  const handleSave = () => {
    if (!listName) {
      message.error("Filter name cannot be empty");
      return;
    }

    setLoading(true);

    setTimeout(() => {
      const sql = formatQuery(query, "sql");
      const newQuery = { sql };
      setLoading(false);
      message.success("Query & List saved successfully!");
    }, 2000);
  };

  const handleApplyFilter = async () => {
    setFilterLoading(true);

    const loginData = JSON.parse(localStorage.getItem("logindata"));
    const token = loginData ? loginData.accessToken : null;

    if (!token) {
      message.error("No access token found. Please log in.");
      setFilterLoading(false);
      return;
    }

    try {
      const response = await fetch(
        "https://usfarmdata.org/fastapi/contact_function/?operation=search",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({
            filter: sql,
          }),
        }
      );

      // Check response status
      console.log("Response status:", response.status);

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.detail || "Failed to apply filter.");
      }

      const data = await response.json();
      console.log("Response data:", data); // Log the response data
      setFilterData(data);
      setIsModalVisible(true);
      message.success("Filter applied successfully!");
    } catch (error) {
      message.error(`Error: ${error.message}`);
      console.error("Error applying filter:", error); // Log the error
    } finally {
      setFilterLoading(false);
    }
  };

  const handleCopyQuery = () => {
    navigator.clipboard.writeText(liveQuery).then(
      () => {
        message.success("Query copied to clipboard!");
      },
      (err) => {
        message.error("Failed to copy query to clipboard.");
      }
    );
  };

  const handleMouseDown = () => {
    setIsDragging(true);
  };

  const handleMouseUp = () => {
    setIsDragging(false);
  };

  const handleMouseMove = (e) => {
    if (isDragging) {
      const newWidth = (e.clientX / window.innerWidth) * 100;
      if (newWidth >= 20 && newWidth <= 80) {
        setParent1Width(newWidth);
      }
    }
  };

  React.useEffect(() => {
    document.addEventListener("mousemove", handleMouseMove);
    document.addEventListener("mouseup", handleMouseUp);
    return () => {
      document.removeEventListener("mousemove", handleMouseMove);
      document.removeEventListener("mouseup", handleMouseUp);
    };
  }, [isDragging]);

  return (
    <div className="bg-sky-100 h-full p-2" onMouseUp={handleMouseUp}>
      <div className="flex h-full gap-2">
        <div
          className="w-4/5 bg-white rounded-lg h-full grow"
          style={{
            boxShadow: "0px 3px 6px #00000029",
            width: `${parent1Width}%`,
          }}
        >
          <Container alignBox="column">
            <Box className="px-3 py-4 text-lg font-roboto font-semibold border-b">
              <div>Filters</div>
              <div className="text-sm text-gray-400 font-normal">
                <span className="text-yellow-600 pr-px">
                  <InfoIcon fontSize="small" />
                </span>
                To save your filter list, please enter a filter name and click
                'Save as List'
              </div>
            </Box>
            <Box flexible scroll="vertical">
              <div className="py-5 px-5">
                <div>
                  <label
                    htmlFor="listname"
                    className="block mb-1 text-sm font-medium text-gray-900"
                  >
                    Filter Name
                  </label>
                  <input
                    type="text"
                    id="listname"
                    value={listName}
                    onChange={handleListNameChange}
                    placeholder="Enter Filter Name"
                    className="w-3/5 py-2.5 px-0 text-sm text-gray-500 bg-transparent border-0 border-b border-gray-200 appearance-none focus:outline-none focus:ring-0 focus:border-gray-200 peer"
                  />
                </div>
              </div>
              <div className="py-5 px-5">
                <QueryBuilderAntD>
                  <QueryBuilder
                    fields={fields}
                    query={query}
                    onQueryChange={handleQueryChange}
                    showCloneButtons
                    showIndependentCombinators
                    showBranches
                    controlClassnames={{
                      queryBuilder: "queryBuilder-branches",
                    }}
                  />
                </QueryBuilderAntD>
              </div>
            </Box>
            <Box className="py-3 border-t">
              <Flex
                gap="middle"
                align="center"
                style={{ height: "100%", padding: "0 1rem" }}
                justify="left"
              >
                <Button type="primary" onClick={handleSave} loading={loading}>
                  Save as List
                </Button>
                <Button
                  type="primary"
                  onClick={handleApplyFilter}
                  loading={filterLoading}
                >
                  Apply Filter
                </Button>
                <Button onClick={handleClearQuery}>Clear All</Button>
              </Flex>
            </Box>
          </Container>
        </div>
        <Tooltip title="Drag to resize" placement="top">
          <Resizer onMouseDown={handleMouseDown} />
        </Tooltip>
        <div
          className="w-1/3 bg-white rounded-lg"
          style={{
            boxShadow: "0px 3px 6px #00000029",
            width: `${100 - parent1Width}%`,
          }}
        >
          <div className="flex flex-col h-full">
            <div className="p-3 border-b">Created Queries</div>
            <div className="grow overflow-y-auto">
              <div className="p-3">
                <div className="p-2 bg-slate-200 rounded text-sm font-semibold font-segoe">
                  Query: {liveQuery || "No live query"}
                </div>
              </div>
            </div>
            <div className="p-3 border-t">
              <Button
                type="primary"
                onClick={handleCopyQuery}
                icon={<ContentCopyIcon style={{ fontSize: "1rem" }} />}
              >
                Copy Query
              </Button>
            </div>
          </div>
        </div>
      </div>
      <CustomModel
        open={isModalVisible}
        onCancel={() => setIsModalVisible(false)}
        title="Filter Results"
        width={1000}
      >
        <div className="p-5">
          <table className="w-full text-left">
            <thead>
              <tr>
                <th className="border-b py-2">Firstname</th>
                <th className="border-b py-2">Lastname</th>
                <th className="border-b py-2">Email</th>
                <th className="border-b py-2">Company</th>
                <th className="border-b py-2">Phone</th>
              </tr>
            </thead>
            <tbody className="max-h-96 overflow-x-auto">
              {filterData.length > 0 ? (
                filterData.map((item, index) => (
                  <tr key={index}>
                    <td className="border-b py-2">{item.main_firstname}</td>
                    <td className="border-b py-2">{item.main_lastname}</td>
                    <td className="border-b py-2">{item.main_email}</td>
                    <td className="border-b py-2">{item.main_company}</td>
                    <td className="border-b py-2">{item.main_phone}</td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="5" className="text-center py-4">
                    No data available
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </CustomModel>
    </div>
  );
};

export default CustomQueryBuilder;
