import React from "react";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { oneLight } from "react-syntax-highlighter/dist/esm/styles/prism";

const SqlCodePreview = ({ sqlString }) => {
  const lineProps = (lineNumber) => {
    let style = { display: "block" };
    if (lineNumber === 2) {
      style.backgroundColor = "#ffdd57"; // Highlight color for line 2
    }
    return { style };
  };

  return (
    <SyntaxHighlighter
      language="sql"
      style={oneLight}
      wrapLines
      lineProps={lineProps}
    >
      {sqlString}
    </SyntaxHighlighter>
  );
};

export default SqlCodePreview;
