import React, { useState, useEffect, useRef } from "react";
import { Container, Box } from "@lib/Layout";
import { useNavigate, useLocation } from "react-router-dom";
import CustomTable from "@components/CustomTable/CustomTable";
// import ProfilePage from "../../ProfilePage/ProfilePage";
import ContactHeader from "../ContactHeader/ContactHeader";
import ContactSubHeader from "../ContactSubHeader/ContactSubHeader";
import RecentHistory from "../RecentHistory/RecentHistory";
import FieldContainer from "../FieldContainer/FieldContainer";
import { useWindowWidth } from "../../../context/WindowContext";
import NoteModel from "../NoteModel/NoteModel";
import useFetchListData from "../../../hooks/useFetchListData";
import updateContactApi from "../../../hooks/updateContactApi";
import useConactBoxDragAndDrop from "../../../hooks/useConactBoxDragAndDrop";
import columns from "./contactColumns";
import { preprocessData, Loading, Error } from "./contactManagementUtils";
import TextBoxField from "@components/TextBoxField/TextBoxField";
import SelectBoxField from "@components/SelectBoxField/SelectBoxField";
import DateTimeField from "@components/DateTimeField/DateTimeField";
import DOMPurify from "dompurify";

import {
  Button,
  Flex,
  message,
  Modal,
  Tooltip,
  Drawer,
  List,
  Typography,
} from "antd";

import VisibilityIcon from "@mui/icons-material/Visibility";
import { useGmail } from "../../../context/GmailContext";
import _ from "lodash";
const { Title, Paragraph, Text, Link } = Typography;
const ProfilePage = ({ ProfileDetails, onFieldChange }) => {
  return (
    <Container alignBox="row" wrap="wrap" className="gap-y-3 gap-x-8">
      <div>
        <TextBoxField
          label="First Name"
          value={ProfileDetails?.profile_firstname || ""}
          onChange={(e) =>
            onFieldChange("ProfileDetails.profile_firstname", e.target.value)
          }
        />
      </div>
      <div>
        <TextBoxField
          label="Last Name"
          value={ProfileDetails?.profile_lastname || ""}
          onChange={(e) =>
            onFieldChange("ProfileDetails.profile_lastname", e.target.value)
          }
        />
      </div>
      <div>
        <TextBoxField
          label="Company"
          value={ProfileDetails?.profile_company || ""}
          onChange={(e) =>
            onFieldChange("ProfileDetails.profile_company", e.target.value)
          }
        />
      </div>
      <div>
        <TextBoxField
          label="Phone"
          value={ProfileDetails?.profile_phone || ""}
          onChange={(e) =>
            onFieldChange("ProfileDetails.profile_phone", e.target.value)
          }
        />
      </div>
      <div>
        <TextBoxField
          label="Email"
          value={ProfileDetails?.profile_email || ""}
          onChange={(e) =>
            onFieldChange("ProfileDetails.profile_email", e.target.value)
          }
        />
      </div>
      <div>
        <TextBoxField
          label="Address 1"
          value={ProfileDetails?.profile_address1 || ""}
          onChange={(e) =>
            onFieldChange("ProfileDetails.profile_address1", e.target.value)
          }
        />
      </div>
      <div>
        <TextBoxField
          label="Address 2"
          value={ProfileDetails?.profile_address2 || ""}
          onChange={(e) =>
            onFieldChange("ProfileDetails.profile_address2", e.target.value)
          }
        />
      </div>
      <div>
        <TextBoxField
          label="City"
          value={ProfileDetails?.profile_city || ""}
          onChange={(e) =>
            onFieldChange("ProfileDetails.profile_city", e.target.value)
          }
        />
      </div>
      <div>
        <TextBoxField
          label="State"
          value={ProfileDetails?.profile_state || ""}
          onChange={(e) =>
            onFieldChange("ProfileDetails.profile_state", e.target.value)
          }
        />
      </div>
      <div>
        <TextBoxField
          label="Zipcode"
          value={ProfileDetails?.profile_zipcode || ""}
          onChange={(e) =>
            onFieldChange("ProfileDetails.profile_zipcode", e.target.value)
          }
        />
      </div>
    </Container>
  );
};

// Business Page Component
const BusinessPage = ({ BusinessDetails, onFieldChange }) => {
  return (
    <Container alignBox="row" wrap="wrap" className="gap-y-3 gap-x-8">
      <div>
        <TextBoxField
          label="SIC Code"
          value={BusinessDetails?.Busi_siccode || ""}
          onChange={(e) =>
            onFieldChange("BusinessDetails.Busi_siccode", e.target.value)
          }
        />
      </div>
      <div>
        <TextBoxField
          label="Industry Keyword"
          value={BusinessDetails?.Busi_industrykeyword || ""}
          onChange={(e) =>
            onFieldChange(
              "BusinessDetails.Busi_industrykeyword",
              e.target.value
            )
          }
        />
      </div>
      <div>
        <TextBoxField
          label="Employee Size"
          value={BusinessDetails?.Busi_employeesize || ""}
          onChange={(e) =>
            onFieldChange("BusinessDetails.Busi_employeesize", e.target.value)
          }
        />
      </div>
      <div>
        <TextBoxField
          label="Revenue"
          value={BusinessDetails?.Busi_revenue || ""}
          onChange={(e) =>
            onFieldChange("BusinessDetails.Busi_revenue", e.target.value)
          }
        />
      </div>
      <div>
        <TextBoxField
          label="Website"
          value={BusinessDetails?.Busi_website || ""}
          onChange={(e) =>
            onFieldChange("BusinessDetails.Busi_website", e.target.value)
          }
        />
      </div>
      <div>
        <TextBoxField
          label="Small Business"
          value={BusinessDetails?.Busi_smallbusiness || ""}
          onChange={(e) =>
            onFieldChange("BusinessDetails.Busi_smallbusiness", e.target.value)
          }
        />
      </div>
      <div>
        <TextBoxField
          label="Year Started"
          value={BusinessDetails?.Busi_yearstarted || ""}
          onChange={(e) =>
            onFieldChange("BusinessDetails.Busi_yearstarted", e.target.value)
          }
        />
      </div>
    </Container>
  );
};

// Consumer Page Component
const ConsumerPage = ({ ConsumerDetails, onFieldChange }) => {
  const [selectedDateTime, setSelectedDateTime] = useState(
    ConsumerDetails?.cons_dateofbirth || ""
  );

  const handleDateChange = (event) => {
    const value = event.target.value;
    setSelectedDateTime(value);
    onFieldChange("ConsumerDetails.cons_dateofbirth", value);
  };

  return (
    <Container alignBox="row" wrap="wrap" className="gap-y-3 gap-x-8">
      <div>
        <TextBoxField
          label="Age"
          value={ConsumerDetails?.cons_age || ""}
          onChange={(e) =>
            onFieldChange("ConsumerDetails.cons_age", e.target.value)
          }
        />
      </div>
      <div>
        <TextBoxField
          label="Gender"
          value={ConsumerDetails?.cons_gender || ""}
          onChange={(e) =>
            onFieldChange("ConsumerDetails.cons_gender", e.target.value)
          }
        />
      </div>
      <div>
        <DateTimeField
          value={ConsumerDetails?.cons_dateofbirth || selectedDateTime}
          onChange={handleDateChange}
          placeholder="Select date and time"
          name="dateTime"
          label="Date of Birth"
        />
      </div>
      <div>
        <TextBoxField
          label="Marital Status"
          value={ConsumerDetails?.cons_maritalstatus || ""}
          onChange={(e) =>
            onFieldChange("ConsumerDetails.cons_maritalstatus", e.target.value)
          }
        />
      </div>
      <div>
        <TextBoxField
          label="Dwelling Type"
          value={ConsumerDetails?.cons_dwellingtype || ""}
          onChange={(e) =>
            onFieldChange("ConsumerDetails.cons_dwellingtype", e.target.value)
          }
        />
      </div>
      <div>
        <TextBoxField
          label="Estimated Household Income"
          value={ConsumerDetails?.cons_estimatedhouseholdincome || ""}
          onChange={(e) =>
            onFieldChange(
              "ConsumerDetails.cons_estimatedhouseholdincome",
              e.target.value
            )
          }
        />
      </div>
      <div>
        <TextBoxField
          label="Home Market Value"
          value={ConsumerDetails?.cons_homemarketvalue || ""}
          onChange={(e) =>
            onFieldChange(
              "ConsumerDetails.cons_homemarketvalue",
              e.target.value
            )
          }
        />
      </div>
      <div>
        <TextBoxField
          label="Home Owner"
          value={ConsumerDetails?.cons_homeowner || ""}
          onChange={(e) =>
            onFieldChange("ConsumerDetails.cons_homeowner", e.target.value)
          }
        />
      </div>
      <div>
        <TextBoxField
          label="Household Size"
          value={ConsumerDetails?.cons_householdsize || ""}
          onChange={(e) =>
            onFieldChange("ConsumerDetails.cons_householdsize", e.target.value)
          }
        />
      </div>
    </Container>
  );
};

// Farm Page Component
const FarmPage = ({ FarmDetails, onFieldChange }) => {
  return (
    <Container alignBox="row" wrap="wrap" className="gap-y-3 gap-x-8">
      <div>
        <TextBoxField
          label="Record ID"
          value={FarmDetails?.farm_farmid || ""}
          onChange={(e) =>
            onFieldChange("FarmDetails.farm_farmid", e.target.value)
          }
        />
      </div>
      <div>
        <TextBoxField
          label="Crop"
          value={FarmDetails?.farm_crops || ""}
          onChange={(e) =>
            onFieldChange("FarmDetails.farm_crops", e.target.value)
          }
        />
      </div>
      <div>
        <TextBoxField
          label="Livestock"
          value={FarmDetails?.farm_livestock || ""}
          onChange={(e) =>
            onFieldChange("FarmDetails.farm_livestock", e.target.value)
          }
        />
      </div>
      <div>
        <TextBoxField
          label="Livestock Total Head"
          value={FarmDetails?.farm_livestockhead || ""}
          onChange={(e) =>
            onFieldChange("FarmDetails.farm_livestockhead", e.target.value)
          }
        />
      </div>
      <div>
        <TextBoxField
          label="Exact Acres"
          value={FarmDetails?.farm_exactacres || ""}
          onChange={(e) =>
            onFieldChange("FarmDetails.farm_exactacres", e.target.value)
          }
        />
      </div>
      <div>
        <TextBoxField
          label="Estimated Revenue"
          value={FarmDetails?.farm_estimatedrevenue || ""}
          onChange={(e) =>
            onFieldChange("FarmDetails.farm_estimatedrevenue", e.target.value)
          }
        />
      </div>
      <div>
        <TextBoxField
          label="Producer Type"
          value={FarmDetails?.farm_producertype || ""}
          onChange={(e) =>
            onFieldChange("FarmDetails.farm_producertype", e.target.value)
          }
        />
      </div>
      <div>
        <TextBoxField
          label="Rural"
          value={FarmDetails?.farm_rural || ""}
          onChange={(e) =>
            onFieldChange("FarmDetails.farm_rural", e.target.value)
          }
        />
      </div>
      <div>
        <TextBoxField
          label="SIC Code"
          value={FarmDetails?.farm_farmsic || ""}
          onChange={(e) =>
            onFieldChange("FarmDetails.farm_farmsic", e.target.value)
          }
        />
      </div>
      <div>
        <TextBoxField
          label="Entity Type"
          value={FarmDetails?.farm_entitytype || ""}
          onChange={(e) =>
            onFieldChange("FarmDetails.farm_entitytype", e.target.value)
          }
        />
      </div>
    </Container>
  );
};

// Property Page Component
const PropertyPage = ({ PropertyDetails, onFieldChange }) => {
  return (
    <Container alignBox="row" wrap="wrap" className="gap-y-3 gap-x-8">
      <div>
        <TextBoxField
          label="Clip ID"
          value={PropertyDetails?.prop_clipid || ""}
          onChange={(e) =>
            onFieldChange("PropertyDetails.prop_clipid", e.target.value)
          }
        />
      </div>
      <div>
        <TextBoxField
          label="Property APN"
          value={PropertyDetails?.prop_propertyapn || ""}
          onChange={(e) =>
            onFieldChange("PropertyDetails.prop_propertyapn", e.target.value)
          }
        />
      </div>
      <div>
        <TextBoxField
          label="Latitude"
          value={PropertyDetails?.prop_latitude || ""}
          onChange={(e) =>
            onFieldChange("PropertyDetails.prop_latitude", e.target.value)
          }
        />
      </div>
      <div>
        <TextBoxField
          label="Longitude"
          value={PropertyDetails?.prop_longitude || ""}
          onChange={(e) =>
            onFieldChange("PropertyDetails.prop_longitude", e.target.value)
          }
        />
      </div>
      <div>
        <TextBoxField
          label="Situs Address"
          value={PropertyDetails?.prop_situsaddress || ""}
          onChange={(e) =>
            onFieldChange("PropertyDetails.prop_situsaddress", e.target.value)
          }
        />
      </div>
      <div>
        <TextBoxField
          label="Land Square Footage"
          value={PropertyDetails?.prop_landsquarefootage || ""}
          onChange={(e) =>
            onFieldChange(
              "PropertyDetails.prop_landsquarefootage",
              e.target.value
            )
          }
        />
      </div>
      <div>
        <TextBoxField
          label="Land Use Code"
          value={PropertyDetails?.prop_landusecode || ""}
          onChange={(e) =>
            onFieldChange("PropertyDetails.prop_landusecode", e.target.value)
          }
        />
      </div>
      <div>
        <TextBoxField
          label="Mailing Address"
          value={PropertyDetails?.prop_mailingaddress || ""}
          onChange={(e) =>
            onFieldChange("PropertyDetails.prop_mailingaddress", e.target.value)
          }
        />
      </div>
    </Container>
  );
};

const ContactMangementHome = () => {
  const [isGridView, setIsGridView] = useState(true);
  const windowWidth = useWindowWidth();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [saveloading, setLoading] = useState(false);
  const { data, error, loading } = useFetchListData();
  const [currentIndex, setCurrentIndex] = useState(0);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const processedData = preprocessData(data || []);
  const totalCount = data ? data.length : 0;
  const [editedData, setEditedData] = useState({});
  const [updateLoading, setUpdateLoading] = useState(false);
  const currentDataItem = data && data.length > 0 ? data[currentIndex] : {};
  const [isEditing, setIsEditing] = useState(false);

  console.log(currentDataItem, "currentDataItem");

  const setNestedState = (obj, path, value) => {
    const keys = path.split(".");
    const lastKey = keys.pop();
    const newObj = _.cloneDeep(obj); // Deep clone to ensure immutability
    const deep = keys.reduce((acc, key) => {
      if (!acc[key]) acc[key] = {};
      return acc[key];
    }, newObj);
    deep[lastKey] = value;
    return newObj;
  };

  useEffect(() => {
    const storedContactId = localStorage.getItem("contactId");
    if (data && data.length > 0) {
      let foundIndex = 0;
      if (storedContactId) {
        foundIndex = data.findIndex(
          (item) => item.contactid.toString() === storedContactId
        );
        if (foundIndex === -1) foundIndex = 0;
      }
      setCurrentIndex(foundIndex);
      setEditedData(_.cloneDeep(data[foundIndex]));
    }
  }, [data]);

  // Similarly, when currentIndex changes
  useEffect(() => {
    if (data && data.length > 0) {
      setEditedData(_.cloneDeep(data[currentIndex]));
    }
  }, [data, currentIndex]);

  useEffect(() => {
    if (!_.isEqual(currentDataItem, editedData)) {
      setIsEditing(true);
    } else {
      setIsEditing(false);
    }
  }, [editedData, currentDataItem]);

  useEffect(() => {
    if (currentDataItem.contactid) {
      localStorage.setItem("contactId", currentDataItem.contactid.toString());
    }
    if (currentDataItem.ProfileDetails?.profile_email) {
      localStorage.setItem(
        "contactEmail",
        currentDataItem.ProfileDetails.profile_email
      );
    }
  }, [currentDataItem]);

  useEffect(() => {
    return () => {
      localStorage.removeItem("contactId");
      localStorage.removeItem("contactEmail");
    };
  }, []);

  const handleFieldChange = (path, value) => {
    setEditedData((prev) => setNestedState(prev, path, value));
    console.log(editedData, "editedData");
    setIsEditing(true); // Set isEditing to true when a change is made
  };

  const [list, setList] = useState([
    {
      id: "profile",
      title: "Profile Information",
      component: (
        <ProfilePage
          ProfileDetails={editedData.ProfileDetails}
          onFieldChange={handleFieldChange}
        />
      ),
    },
    {
      id: "business",
      title: "Business Information",
      component: (
        <BusinessPage
          BusinessDetails={editedData.BusinessDetails}
          onFieldChange={handleFieldChange}
        />
      ),
    },
    {
      id: "property",
      title: "Property Information",
      component: (
        <PropertyPage
          PropertyDetails={editedData.PropertyDetails}
          onFieldChange={handleFieldChange}
        />
      ),
    },
    {
      id: "farm",
      title: "Farm Information",
      component: (
        <FarmPage
          FarmDetails={editedData.FarmDetails}
          onFieldChange={handleFieldChange}
        />
      ),
    },
    {
      id: "consumer",
      title: "Consumer Information",
      component: (
        <ConsumerPage
          ConsumerDetails={editedData.ConsumerDetails}
          onFieldChange={handleFieldChange}
        />
      ),
    },
  ]);

  // Update the list whenever editedData changes
  useEffect(() => {
    setList([
      {
        id: "profile",
        title: "Profile Information",
        component: (
          <ProfilePage
            ProfileDetails={editedData.ProfileDetails}
            onFieldChange={handleFieldChange}
          />
        ),
      },
      {
        id: "business",
        title: "Business Information",
        component: (
          <BusinessPage
            BusinessDetails={editedData.BusinessDetails}
            onFieldChange={handleFieldChange}
          />
        ),
      },
      {
        id: "property",
        title: "Property Information",
        component: (
          <PropertyPage
            PropertyDetails={editedData.PropertyDetails}
            onFieldChange={handleFieldChange}
          />
        ),
      },
      {
        id: "farm",
        title: "Farm Information",
        component: (
          <FarmPage
            FarmDetails={editedData.FarmDetails}
            onFieldChange={handleFieldChange}
          />
        ),
      },
      {
        id: "consumer",
        title: "Consumer Information",
        component: (
          <ConsumerPage
            ConsumerDetails={editedData.ConsumerDetails}
            onFieldChange={handleFieldChange}
          />
        ),
      },
    ]);
  }, [editedData, currentDataItem]);

  useEffect(() => {
    const savedListOrder = localStorage.getItem("draggedListOrder");
    if (savedListOrder) {
      const orderedIds = JSON.parse(savedListOrder);
      const orderedList = orderedIds.map((id) =>
        list.find((item) => item.id === id)
      );
      setList(orderedList);
    }
  }, []);

  const {
    handleDragStart,
    handleDragEnter,
    handleDragEnd,
    handleDragOver,
    containerRef,
  } = useConactBoxDragAndDrop(list, setList);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleGetCheckboxProps = (record) => {
    return {
      contactid: record.contactid,
    };
  };

  const handleSave = () => {
    setLoading(true);
    setIsEditing(false);
    setTimeout(() => {
      setLoading(false);
      setIsModalVisible(false);
    }, 2000);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const handleFilter = () => {
    navigate("/filter");
  };

  const handleViewToggle = (isGrid) => {
    const view = isGrid ? "grid" : "list";
    localStorage.setItem("view", view);
    setIsGridView(isGrid);
  };

  const navigate = useNavigate();
  const showConfirm = (callback) => {
    Modal.confirm({
      title: "Unsaved Changes",
      content:
        "You have unsaved changes. Do you want to discard them and continue?",
      onOk: callback,
      onCancel() {},
    });
  };

  const handleNext = () => {
    if (!_.isEqual(currentDataItem, editedData)) {
      showConfirm(() => {
        const nextIndex = Math.min(currentIndex + 1, totalCount - 1);
        const nextDataItem = data[nextIndex];
        setCurrentIndex(nextIndex);
        setEditedData(nextDataItem);
        setIsEditing(false);

        if (nextDataItem.contactid) {
          localStorage.setItem("contactId", nextDataItem.contactid.toString());
        }
        if (nextDataItem.ProfileDetails?.profile_email) {
          localStorage.setItem(
            "contactEmail",
            nextDataItem.ProfileDetails?.profile_email
          );
          setToEmail(nextDataItem.ProfileDetails?.profile_email);
        }
      });
    } else {
      const nextIndex = Math.min(currentIndex + 1, totalCount - 1);
      const nextDataItem = data[nextIndex];
      setCurrentIndex(nextIndex);
      setEditedData(nextDataItem);
      setIsEditing(false);

      if (nextDataItem.contactid) {
        localStorage.setItem("contactId", nextDataItem.contactid.toString());
      }
      if (nextDataItem.ProfileDetails?.profile_email) {
        localStorage.setItem(
          "contactEmail",
          nextDataItem.ProfileDetails?.profile_email
        );
        setToEmail(nextDataItem.ProfileDetails?.profile_email);
      }
    }
  };

  const handlePrevious = () => {
    if (!_.isEqual(currentDataItem, editedData)) {
      showConfirm(() => {
        const prevIndex = Math.max(currentIndex - 1, 0);
        const prevDataItem = data[prevIndex];
        setCurrentIndex(prevIndex);
        setEditedData(prevDataItem);
        setIsEditing(false);
        if (prevDataItem.contactid) {
          localStorage.setItem("contactId", prevDataItem.contactid.toString());
        }
        if (prevDataItem.ProfileDetails?.profile_email) {
          localStorage.setItem(
            "contactEmail",
            prevDataItem.ProfileDetails?.profile_email
          );
          setToEmail(prevDataItem.ProfileDetails?.profile_email);
        }
      });
    } else {
      const prevIndex = Math.max(currentIndex - 1, 0);
      const prevDataItem = data[prevIndex];
      setCurrentIndex(prevIndex);
      setEditedData(prevDataItem);
      setIsEditing(false);
      if (prevDataItem.contactid) {
        localStorage.setItem("contactId", prevDataItem.contactid.toString());
      }
      if (prevDataItem.ProfileDetails?.profile_email) {
        localStorage.setItem(
          "contactEmail",
          prevDataItem.ProfileDetails?.profile_email
        );
        setToEmail(prevDataItem.ProfileDetails?.profile_email);
      }
    }
  };

  const handleEdit = (record) => {
    localStorage.setItem("contactId", record.contactid);
    localStorage.setItem(
      "contactEmail",
      record.ProfileDetails?.profile_email || ""
    );
    setCurrentIndex(
      data.findIndex((item) => item.contactid === record.contactid)
    );
    setEditedData(record);
    handleViewToggle(true);
    window.location.reload();
  };

  const handleDelete = (record) => {
    console.log("Delete", record);
  };

  const handleSelectChange = (selectedKeys) => {
    console.log(selectedKeys);
    setSelectedRowKeys(selectedKeys);
  };
  const handleUpdate = async () => {
    if (_.isEqual(currentDataItem, editedData)) {
      message.info("No changes detected.");
      return;
    }

    setUpdateLoading(true);
    console.log(editedData, "updateEditdata");
    console.log(currentDataItem.contactid, "contactid");
    try {
      const response = await updateContactApi(
        currentDataItem.contactid,
        editedData
      );
      const successMessage = response.detail || "Contact updated successfully!";
      message.success(successMessage);

      setTimeout(() => {
        window.location.reload();
      }, 1000);
    } catch (error) {
      let errorMessage = "Failed to update contact";

      if (error.message) {
        console.log(error.message, "API Error Message");

        errorMessage = error.message || errorMessage;
        message.error(errorMessage);
      } else {
        message.error("An unknown error occurred.");
      }
    } finally {
      setUpdateLoading(false);
    }
  };

  //email functions
  const {
    login,
    token,
    emailLoading,
    emails,
    emailError,
    getEmailsByFromAndTo,
    fetchAttachment,
  } = useGmail();

  const loginData = JSON.parse(localStorage.getItem("logindata"));

  const [activeTab, setActiveTab] = useState("email");
  const [isDrawerVisible, setDrawerVisible] = useState(false);
  const [selectedEmail, setSelectedEmail] = useState(null);
  const [isIncoming, setIsIncoming] = useState(true);
  const [fromEmail, setFromEmail] = useState(
    loginData ? loginData.email : null
  );
  const [toEmail, setToEmail] = useState(
    localStorage.getItem("contactEmail") || ""
  );
  const [attachmentLoadingId, setAttachmentLoadingId] = useState(null);
  const [attachmentErrorId, setAttachmentErrorId] = useState(null);
  const [loadingAttachmentIndex, setLoadingAttachmentIndex] = useState(null);

  const getEmailsByFromAndToRef = useRef(getEmailsByFromAndTo);

  // Update the ref if the function changes
  useEffect(() => {
    getEmailsByFromAndToRef.current = getEmailsByFromAndTo;
  }, [getEmailsByFromAndTo]);

  // Initial fetch
  useEffect(() => {
    if (token && fromEmail && toEmail) {
      getEmailsByFromAndToRef.current(fromEmail, toEmail);
    }
  }, [token, fromEmail, toEmail]);

  const fetchIncomingEmails = () => {
    getEmailsByFromAndTo(fromEmail, toEmail);
    setIsIncoming(true);
  };

  const fetchOutgoingEmails = () => {
    getEmailsByFromAndTo(toEmail, fromEmail);
    setIsIncoming(false);
  };

  const handleTabClick = (tabName) => {
    setActiveTab(tabName);
  };

  const handleEmailClick = (email) => {
    setSelectedEmail(email);
    setDrawerVisible(true);
  };

  const onCloseDrawer = () => {
    setDrawerVisible(false);
    setSelectedEmail(null);
  };
  const handleLazyDownloadAttachment = async (emailId, attachment, index) => {
    setAttachmentLoadingId(emailId);
    setLoadingAttachmentIndex(index); // Set the current loading attachment index
    setAttachmentErrorId(null);

    const fetchedAttachment = await fetchAttachment(emailId, attachment);
    setAttachmentLoadingId(null);
    setLoadingAttachmentIndex(null); // Reset after loading

    if (fetchedAttachment && fetchedAttachment.url) {
      // Create a temporary link to trigger download
      const link = document.createElement("a");
      link.href = fetchedAttachment.url;
      link.download = fetchedAttachment.filename;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

      // Revoke the object URL after a short delay to ensure the download starts
      setTimeout(() => {
        URL.revokeObjectURL(fetchedAttachment.url);
      }, 100); // Adjust the timeout as necessary
    } else {
      setAttachmentErrorId(emailId);
      message.error(`Failed to download attachment: ${attachment.filename}`);
    }
  };

  return (
    <Container
      className="py-3 px-4 gap-8"
      alignBox="column"
      style={{ backgroundColor: isGridView ? "#fff" : "#F3F7F9" }}
    >
      <Box>
        <ContactHeader
          handleFilter={handleFilter}
          onToggle={handleViewToggle}
          nextClick={handleNext}
          prewClick={handlePrevious}
          currentIndex={currentIndex + 1}
          totalCount={totalCount}
          needActions={isGridView ? true : false}
        />
      </Box>
      {isGridView && (
        <Box>
          <ContactSubHeader
            title={
              (currentDataItem?.ProfileDetails?.profile_firstname || "") +
              " " +
              (currentDataItem?.ProfileDetails?.profile_lastname || "")
            }
            contactId={currentDataItem.contactid}
            handleNote={showModal}
            companyName={
              currentDataItem.ProfileDetails?.profile_company || "Not Available"
            }
            Phone={
              currentDataItem.ProfileDetails?.profile_phone || "Not Available"
            }
            Email={
              currentDataItem.ProfileDetails?.profile_email || "Not Available"
            }
            Adders={
              (currentDataItem?.ProfileDetails?.profile_address1 || "") +
              " , " +
              (currentDataItem?.ProfileDetails?.profile_address2 || "")
            }
            isloading={loading}
            isEditing={isEditing}
          />
        </Box>
      )}
      <Box flexible>
        {isGridView ? (
          <Container
            alignBox={windowWidth > 600 ? "row" : "column"}
            className="gap-4"
          >
            <Box flexible={windowWidth > 600 ? true : false}>
              <Container className="gap-2">
                <Box flexible>
                  <div
                    className="h-full overflow-auto scroll-smooth"
                    ref={containerRef}
                    onDragOver={handleDragOver}
                  >
                    <div className="flex flex-col gap-5 px-2">
                      {list.map((item, index) => (
                        <FieldContainer
                          key={item.id}
                          title={item.title}
                          draggable
                          onDragStart={(e) => handleDragStart(e, index)}
                          onDragEnter={(e) => handleDragEnter(e, index)}
                          onDragEnd={handleDragEnd}
                        >
                          {item.component}
                        </FieldContainer>
                      ))}
                    </div>
                  </div>
                </Box>
                {isEditing && (
                  <Box className="border-t rounded-md p-2">
                    <Flex gap="small" wrap>
                      <Button>Cancel</Button>
                      <Button
                        type="primary"
                        onClick={handleUpdate}
                        loading={updateLoading ? true : false}
                      >
                        Update
                      </Button>
                    </Flex>
                  </Box>
                )}
              </Container>
            </Box>
            <Box>
              <RecentHistory>
                <Box className="h-full">
                  <Container>
                    <Box>
                      <div className="flex border-b gap-5 px-3">
                        <button
                          className={`py-2 cursor-pointer transition-all duration-300 ease-in-out ${
                            activeTab === "email"
                              ? "border-b-2 border-blue-500 font-semibold text-slate-700"
                              : "border-b-2 border-transparent text-gray-500"
                          }`}
                          onClick={() => handleTabClick("email")}
                        >
                          Email Activities
                        </button>
                        <button
                          className={`py-2 cursor-pointer transition-all duration-300 ease-in-out ${
                            activeTab === "phone"
                              ? "border-b-2 border-blue-500 font-semibold  text-slate-700"
                              : "border-b-2 border-transparent text-gray-500"
                          }`}
                          onClick={() => handleTabClick("phone")}
                        >
                          Zoom Phone
                        </button>
                      </div>
                    </Box>
                    <Box flexible>
                      {activeTab === "email" && (
                        <Container className="p-2 gap-2 bg-sky-50">
                          {/* <Box
                            style={{
                              boxShadow:
                                "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
                            }}
                            className="p-2 rounded-md bg-white"
                          >
                            <div className="text-sm text-slate-600 font-semibold">
                              <div>
                                <div className="flex items-center gap-2">
                                  <span className="text-green-700">From: </span>{" "}
                                  <span>{fromEmail}</span>
                                </div>
                              </div>
                              <div>
                                <div className="flex items-center gap-2">
                                  <span className="text-red-700">To: </span>{" "}
                                  <span>{toEmail}</span>
                                </div>
                              </div>
                            </div>
                          </Box> */}
                          <Box
                            style={{
                              boxShadow:
                                "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
                            }}
                            className="p-2 rounded-md bg-white"
                          >
                            <div className="flex items-center gap-3">
                              <Button
                                type={isIncoming ? "primary" : "default"}
                                onClick={fetchIncomingEmails}
                              >
                                Incoming
                              </Button>
                              <Button
                                type={!isIncoming ? "primary" : "default"}
                                onClick={fetchOutgoingEmails}
                              >
                                Outgoing
                              </Button>
                            </div>
                          </Box>
                          <Box flexible scroll="vertical">
                            {!token && (
                              <Button type="primary" ghost onClick={login}>
                                Authorize
                              </Button>
                            )}
                            {emailLoading && (
                              <div className="h-full flex flex-col gap-2 justify-center items-center">
                                <div role="status">
                                  <svg
                                    aria-hidden="true"
                                    className="inline w-8 h-8 text-gray-200 animate-spin fill-green-500"
                                    viewBox="0 0 100 101"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                                      fill="currentColor"
                                    />
                                    <path
                                      d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                                      fill="currentFill"
                                    />
                                  </svg>
                                </div>
                                {/* <span className="text-slate-600">
                                  Fetching Emails... Please wait.
                                </span> */}
                              </div>
                            )}
                            {!emailLoading && emailError && (
                              <div className="h-full flex justify-center items-center">
                                <span className="text-red-600">
                                  {emailError}
                                </span>
                              </div>
                            )}
                            {emails.length === 0 && !emailLoading && (
                              <div className="h-full flex justify-center items-center">
                                <span className="text-red-600">
                                  No Email Found in this Contact.
                                </span>
                              </div>
                            )}
                            {emails.length > 0 && (
                              <ul className="flex flex-col gap-2">
                                {emails.map((email) => (
                                  <li
                                    key={email.id}
                                    style={{
                                      boxShadow:
                                        "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
                                    }}
                                    className="rounded-md"
                                  >
                                    <Container
                                      alignBox="row"
                                      align="vertical"
                                      className="gap-2 bg-white p-2 rounded-md"
                                    >
                                      <Box flexible>
                                        <div className="flex items-center gap-2 text-slate-800">
                                          <span className="text-sm text-slate-500">
                                            Subject:
                                          </span>
                                          <Tooltip title={email.subject}>
                                            <span
                                              className="text-sm truncate cursor-pointer"
                                              style={{ maxWidth: "70%" }}
                                            >
                                              {email.subject}
                                            </span>
                                          </Tooltip>
                                        </div>
                                        <div className="flex items-center gap-2">
                                          <span className="text-sm text-slate-500">
                                            Date:
                                          </span>
                                          <span className="text-sm">
                                            {email.date}
                                          </span>
                                        </div>
                                      </Box>

                                      <div>
                                        <Tooltip title="View Email">
                                          <div
                                            onClick={() =>
                                              handleEmailClick(email)
                                            }
                                            className="h-8 w-8 rounded-full hover:bg-gray-200 hover:text-blue-700 cursor-pointer flex items-center justify-center"
                                          >
                                            <VisibilityIcon
                                              style={{ fontSize: "18px" }}
                                            />
                                          </div>
                                        </Tooltip>
                                      </div>
                                    </Container>
                                  </li>
                                ))}
                              </ul>
                            )}
                            {/* You may want to implement the Drawer component here to display the selected email */}
                          </Box>
                        </Container>
                      )}
                      {activeTab === "phone" && <div>phone</div>}
                    </Box>
                  </Container>
                </Box>
              </RecentHistory>
            </Box>
          </Container>
        ) : (
          <Container
            alignBox="column"
            style={{ boxShadow: "0px 1px 10px #0000000F" }}
            className="bg-white rounded"
          >
            <Box className="border-b">
              <Container alignBox="row" align="vertical" className="px-3 py-2">
                <Box
                  flexible
                  className="font-roboto font-semibold text-lg"
                  style={{ color: "rgb(58, 58, 58)" }}
                >
                  Contacts
                </Box>
                <div>
                  <Button type="primary">Save as List</Button>
                </div>
              </Container>
            </Box>
            <Box className="p-2" flexible={loading ? true : false}>
              {loading ? (
                <Loading />
              ) : error ? (
                <Error />
              ) : (
                <CustomTable
                  columns={columns}
                  data={processedData}
                  rowKey="contactid"
                  onEdit={handleEdit}
                  onSelectChange={handleSelectChange}
                  onDelete={handleDelete}
                  getCheckboxProps={handleGetCheckboxProps}
                  selectableRows={true}
                  selectedRowKeys={selectedRowKeys}
                  EmptyMessage={() => <div>No Data Available</div>}
                />
              )}
            </Box>
          </Container>
        )}
      </Box>
      <NoteModel
        title="Add Note"
        open={isModalVisible}
        onSave={handleSave}
        onCancel={handleCancel}
      />
      <Drawer
        title="Email Details"
        placement="right"
        onClose={onCloseDrawer}
        open={isDrawerVisible}
        width={600}
      >
        {selectedEmail && (
          <div className="p-4">
            <div className="mb-4">
              <strong>From:</strong> <span>{selectedEmail.from}</span>
            </div>
            <div className="mb-4">
              <strong>To:</strong> <span>{selectedEmail.to}</span>
            </div>
            <div className="mb-4">
              <strong>Subject:</strong> <span>{selectedEmail.subject}</span>
            </div>
            <div className="mb-4">
              <strong>Date:</strong> <span>{selectedEmail.date}</span>
            </div>
            <div className="mb-4">
              <strong>Body:</strong>
              <div
                dangerouslySetInnerHTML={{
                  __html: DOMPurify.sanitize(selectedEmail.body),
                }}
                className="py-5"
              />
            </div>
            {selectedEmail.attachments.length > 0 && (
              <div>
                <Text strong>Attachments:</Text>
                <ul>
                  {selectedEmail.attachments.map((att, index) => (
                    <li key={index}>
                      <Tooltip title="Click To Download">
                        <Button
                          style={{ padding: "0" }}
                          type="link"
                          onClick={() =>
                            handleLazyDownloadAttachment(
                              selectedEmail.id,
                              att,
                              index
                            )
                          }
                          loading={
                            attachmentLoadingId === selectedEmail.id &&
                            loadingAttachmentIndex === index
                          }
                        >
                          {att.filename}
                        </Button>
                      </Tooltip>
                    </li>
                  ))}
                </ul>
                {attachmentErrorId === selectedEmail.id && (
                  <Text type="danger">Failed to load some attachments.</Text>
                )}
              </div>
            )}
          </div>
        )}
      </Drawer>
    </Container>
  );
};

export default ContactMangementHome;
