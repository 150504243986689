const columns = [
  {
    title: "Name",
    dataIndex: "name",
    key: "name",
    needSorting: false,
    needSearch: true,
  },
  {
    title: "City",
    dataIndex: "city",
    key: "city",

    needSorting: true,
    needSearch: true,
  },
  {
    title: "Company",
    dataIndex: "company",
    key: "company",
    sorter: (a, b) => a.company.length - b.company.length,
    sortDirections: ["descend", "ascend"],
    needSorting: true,
    needSearch: true,
    ellipsis: true,
  },
  {
    title: "Phone",
    dataIndex: "phone",
    key: "phone",
    needSorting: false,
    needSearch: false,
  },
];

export default columns;
