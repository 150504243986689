import { useState } from "react";
import { message } from "antd";

const useAddSchedule = () => {
  const [addScheduleLoading, setAddScheduleLoading] = useState(false);

  const addSchedule = async (scheduleData) => {
    setAddScheduleLoading(true);

    try {
      const loginData = JSON.parse(localStorage.getItem("logindata"));
      const token = loginData ? loginData.accessToken : null;

      const response = await fetch(
        "https://usfarmdata.org/fastapi/email_function/?operation=addschedule",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({
            Templateid: scheduleData.Templateid,
            Ruleid: scheduleData.Ruleid,
            Schedulename: scheduleData.Schedulename,
            Scheduledatetime: scheduleData.Scheduledatetime,
            CreatedUserId: scheduleData.CreatedUserId,
            Fromemail: scheduleData.Fromemail,
            EmailSubject: scheduleData.EmailSubject,
            FromName: scheduleData.FromName,
          }),
        }
      );

      if (!response.ok) {
        throw new Error(`Error: ${response.status}`);
      }

      const result = await response.json();
      message.success("Schedule added successfully!");
      localStorage.removeItem("scheduledData");
      return result;
    } catch (err) {
      message.error(`Failed to add schedule: ${err.message}`);
    } finally {
      setAddScheduleLoading(false);
    }
  };

  return { addSchedule, addScheduleLoading };
};

export default useAddSchedule;
