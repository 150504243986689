import React, { useEffect, useState } from "react";
import { Container, Box } from "@lib/Layout";
import useFetchTemplates from "../../../api/useFetchTemplates";

const PreviewTemplate = () => {
  const [templateId, setTemplateId] = useState(null);

  useEffect(() => {
    const scheduledData = localStorage.getItem("scheduledData");
    const editScheduleData = localStorage.getItem("EditScheduledData");

    try {
      // Parse data only if it's a string
      const parsedScheduledData = scheduledData
        ? typeof scheduledData === "string"
          ? JSON.parse(scheduledData)
          : scheduledData
        : null;
      const parsedEditScheduleData = editScheduleData
        ? typeof editScheduleData === "string"
          ? JSON.parse(editScheduleData)
          : editScheduleData
        : null;

      if (parsedScheduledData || parsedEditScheduleData) {
        const { emailTemplate } = parsedScheduledData || parsedEditScheduleData;
        setTemplateId(emailTemplate);
      }
    } catch (error) {
      console.error("Error parsing scheduled data:", error);
    }
  }, []);

  const { templates, loading, error } = useFetchTemplates(templateId);

  const generateBlobUrl = (htmlContent) => {
    const blob = new Blob([htmlContent], { type: "text/html" });
    return URL.createObjectURL(blob);
  };

  if (error) return <p>Error: {error}</p>;

  const template = templates;
  console.log(template.templatetxt, "template");

  return (
    <div>
      <Box
        key={template.templateid}
        style={{
          width: "400px",
          height: "450px",
          boxShadow: "rgba(0, 0, 0, 0.1) 0px 4px 12px",
        }}
        className="rounded-lg"
      >
        {loading && (
          <div role="status" class="">
            <div class="flex items-center justify-center h-48 mb-4 bg-gray-300 rounded dark:bg-gray-700">
              <svg
                class="w-10 h-10 text-gray-200 dark:text-gray-600"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="currentColor"
                viewBox="0 0 16 20"
              >
                <path d="M14.066 0H7v5a2 2 0 0 1-2 2H0v11a1.97 1.97 0 0 0 1.934 2h12.132A1.97 1.97 0 0 0 16 18V2a1.97 1.97 0 0 0-1.934-2ZM10.5 6a1.5 1.5 0 1 1 0 2.999A1.5 1.5 0 0 1 10.5 6Zm2.221 10.515a1 1 0 0 1-.858.485h-8a1 1 0 0 1-.9-1.43L5.6 10.039a.978.978 0 0 1 .936-.57 1 1 0 0 1 .9.632l1.181 2.981.541-1a.945.945 0 0 1 .883-.522 1 1 0 0 1 .879.529l1.832 3.438a1 1 0 0 1-.031.988Z" />
                <path d="M5 5V.13a2.96 2.96 0 0 0-1.293.749L.879 3.707A2.98 2.98 0 0 0 .13 5H5Z" />
              </svg>
            </div>
            <div className="p-1">
              <div class="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-48 mb-4"></div>
              <div class="h-2 bg-gray-200 rounded-full dark:bg-gray-700 mb-2.5"></div>
              <div class="h-2 bg-gray-200 rounded-full dark:bg-gray-700 mb-2.5"></div>
              <div class="h-2 bg-gray-200 rounded-full dark:bg-gray-700  mb-2.5"></div>
              <div class="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-48 mb-4"></div>
              <div class="h-2 bg-gray-200 rounded-full dark:bg-gray-700 mb-2.5"></div>
            </div>
          </div>
        )}
        {error && (
          <div className="flex items-center justify-center">
            <span className="text-slate-500">{error}</span>
          </div>
        )}
        {!loading && !error && (
          <Container
            style={{
              overflow: "hidden",
              cursor: "pointer",
              justifyContent: "space-between",
            }}
          >
            <h1 className="p-2 text-slate-700 text-lg font-semibold">
              Selected Template
            </h1>
            <div className="px-2">
              <div className="bg-slate-200   p-2 rounded-md">
                <div className="text-slate-950 font-semibold">
                  {template.templatename}
                </div>
                <p className="text-base">
                  <span className="text-slate-600">Created Date: </span>
                  {new Date(template.createddate).toLocaleDateString()}
                </p>
                <p className="text-base">
                  <span className="text-slate-600">Created by: </span>
                  {template.createdusername}
                </p>
                <a
                  href={generateBlobUrl(template.templatetxt)}
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ color: "#007BFF", textDecoration: "underline" }}
                  className="text-base"
                >
                  View Template
                </a>
              </div>
            </div>

            <Box flexible className="relative p-3" scroll="vertical">
              <div
                dangerouslySetInnerHTML={{
                  __html: template.templatetxt,
                }}
              />
            </Box>
          </Container>
        )}
      </Box>
    </div>
  );
};

export default PreviewTemplate;
