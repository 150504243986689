import React, { useState, useRef, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import ReactQuill from "react-quill";
import { Container, Box } from "@lib/Layout";

import { Form, Input, Button, Upload, Modal, message, Row, Col } from "antd";
import { UploadOutlined } from "@ant-design/icons";
const { TextArea } = Input;

const QuillEditor = () => {
  const quillRef = useRef(null);
  const [editorContent, setEditorContent] = useState("");
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [form] = Form.useForm();
  const navigate = useNavigate();

  const handleEditorChange = (content) => {
    setEditorContent(content);
  };

  const handleShowTemplateClick = () => {
    navigate("/email-campaign");
  };

  const checkIfEditorIsEmpty = () => {
    const trimmedContent = editorContent.trim();
    if (!trimmedContent || trimmedContent === "<p><br></p>") {
      message.warning("Editor is empty. Please write something.");
      return true;
    }
    return false;
  };

  const showHtmlModal = () => {
    if (checkIfEditorIsEmpty()) return;
    setIsModalVisible(true);
  };

  const handleModalOk = () => {
    setIsModalVisible(false);
  };

  const handleModalCancel = () => {
    setIsModalVisible(false);
  };

  const saveTemplate = async () => {
    try {
      await form.validateFields();

      if (checkIfEditorIsEmpty()) {
        message.warning("Editor content is required.");
        return;
      }

      const loginData = JSON.parse(localStorage.getItem("logindata"));
      const token = loginData ? loginData.accessToken : null;
      const userId = loginData ? loginData.userId : "";

      setLoading(true);
      setError(null);

      const htmlDocument = `
        <!DOCTYPE html>
        <html lang="en">
        <head>
            <meta charset="UTF-8">
            <meta name="viewport" content="width=device-width, initial-scale=1.0">
            <title>${form.getFieldValue("templateName")}</title>
            <style>
                body {
                    font-family: Arial, sans-serif;
                    margin: 0;
                    padding: 20px;
                    background-color: #f8f8f8;
                }
            </style>
        </head>
        <body>
            ${editorContent}
        </body>
        </html>
      `;

      console.log("Formatted HTML Document:", htmlDocument);

      const requestData = {
        Userid: userId,
        TemplateName: form.getFieldValue("templateName"),
        Description: form.getFieldValue("description"),
        Templatetxt: htmlDocument,
        Filenamewithextension: `${form.getFieldValue("templateName")}.html`,
      };

      console.log(requestData, "requestData");

      const response = await fetch(
        "https://usfarmdata.org/fastapi/email_function/?operation=createtemplate",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(requestData),
        }
      );

      if (!response.ok) {
        throw new Error("Failed to save template");
      }

      message.success("Template saved successfully!");
    } catch (err) {
      if (err.errorFields) {
        message.error("Please fill in all the required fields.");
      } else {
        setError(err.message);
        message.error(`Error: ${err.message}`);
      }
    } finally {
      setLoading(false);
    }
  };

  const imageHandler = useCallback(() => {
    const input = document.createElement("input");
    input.setAttribute("type", "file");
    input.setAttribute("accept", "image/*");
    input.click();
    input.onchange = async () => {
      if (input.files && input.files[0]) {
        const file = input.files[0];

        const maxSizeInBytes = 1 * 1024 * 1024; // 1 MB
        if (file.size > maxSizeInBytes) {
          message.error("File size must be less than 1 MB.");
          return; // Exit if the file is too large
        }

        const reader = new FileReader();
        reader.onload = (e) => {
          const quill = quillRef.current.getEditor();
          const range = quill.getSelection();
          if (range) {
            quill.insertEmbed(range.index, "image", e.target.result);
          }
        };
        reader.readAsDataURL(file);
      }
    };
  }, []);

  const modules = {
    toolbar: {
      container: [
        [{ font: [] }],
        [{ header: [1, 2, 3, 4, 5, 6, false] }],
        [{ list: "ordered" }, { list: "bullet" }],
        [{ align: [] }],
        ["bold", "italic", "underline", "strike"],
        [{ color: [] }, { background: [] }],
        ["blockquote", "code-block"],
        ["link", "image", "video"],
        ["clean"],
        ["Clear"],
      ],
      handlers: {
        image: imageHandler,
      },
      clipboard: {
        matchVisual: false,
      },
    },
  };

  const formats = [
    "font",
    "header",
    "list",
    "align",
    "bold",
    "italic",
    "underline",
    "strike",
    "color",
    "background",
    "script",
    "blockquote",
    "code-block",
    "link",
    "image",
    "video",
  ];

  const saveAsHtmlFile = () => {
    if (checkIfEditorIsEmpty()) return;

    const htmlDocument = `
      <!DOCTYPE html>
      <html lang="en">
      <head>
          <meta charset="UTF-8">
          <meta name="viewport" content="width=device-width, initial-scale=1.0">
          <title>${form
            .getFieldValue("templateName")
            .replace(".html", "")}</title>
          <style>
              body {
                  font-family: Arial, sans-serif;
                  margin: 0;
                  padding: 20px;
                  background-color: #f8f8f8;
              }
          </style>
      </head>
      <body>
          ${editorContent}
      </body>
      </html>
    `;

    const blob = new Blob([htmlDocument], { type: "text/html" });
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.download = form.getFieldValue("templateName");
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const handleFileUpload = (file) => {
    if (file.type === "text/html") {
      const maxSizeInBytes = 5 * 1024 * 1024; // 5 MB
      if (file.size > maxSizeInBytes) {
        message.error("File size must be less than 5 MB.");
        return false; // Exit if the file is too large
      }

      const reader = new FileReader();
      reader.onload = (e) => {
        const uploadedContent = e.target.result;
        setEditorContent(uploadedContent);
      };
      reader.readAsText(file);
    } else {
      message.error("Please upload a valid HTML file.");
    }
    return false;
  };

  return (
    <div className="w-10/12 h-full" style={{ margin: "0 auto" }}>
      <Container
        className="gap-2 bg-white rounded-md"
        style={{ boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px" }}
      >
        <Box className="p-2 border-b ">
          <Container align="vertical" alignBox="row">
            <Box flexible className="text-lg font-semibold text-slate-600">
              Create New Template
            </Box>
            <Button type="primary" ghost onClick={handleShowTemplateClick}>
              View Available Templates
            </Button>
          </Container>
        </Box>
        <Box flexible className="py-2 px-4">
          <Form layout="vertical" form={form}>
            <Row
              gutter={16}
              // style={{
              //   display: "flex",
              //   flexDirection: "row",
              //   alignItems: "center",
              // }}
            >
              <Col span={8}>
                <Form.Item
                  name="templateName"
                  label="Template Name"
                  rules={[
                    {
                      required: true,
                      message: "Please enter template name",
                    },
                  ]}
                >
                  <Input
                    placeholder="Enter template name"
                    size="large"
                    style={{ width: "300px" }}
                  />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  name="description"
                  label="Description"
                  rules={[
                    {
                      required: true,
                      message: "Please enter description",
                    },
                  ]}
                >
                  <TextArea rows={2} placeholder="Enter description" />
                </Form.Item>
              </Col>
            </Row>

            <div className="flex flex-col gap-2">
              <Upload
                beforeUpload={handleFileUpload}
                accept=".html"
                style={{ marginLeft: "10px" }}
              >
                <Button type="primary" ghost icon={<UploadOutlined />}>
                  Upload HTML
                </Button>
              </Upload>
              <div className="text-lg px-8 text-slate-500">-- OR --</div>
              <div className="mb-4 text-slate-600 font-semibold">
                Create Your Template
              </div>
            </div>
          </Form>

          <div style={{ height: "calc(100vh - 510px)" }}>
            <ReactQuill
              value={editorContent}
              ref={quillRef}
              onChange={handleEditorChange}
              modules={modules}
              formats={formats}
              theme="snow"
              style={{ height: "100%", borderRadius: "5px" }}
            />
          </div>
        </Box>
        <Box className="border-t p-2">
          <div className="flex gap-3">
            <Button
              type="primary"
              loading={loading}
              onClick={saveTemplate}
              disabled={!editorContent}
            >
              Save Template
            </Button>
            <Button
              onClick={showHtmlModal}
              disabled={!editorContent}
              type="primary"
              ghost
            >
              Preview as HTML
            </Button>
            <Button
              onClick={saveAsHtmlFile}
              disabled={!editorContent}
              type="primary"
              ghost
            >
              Download
            </Button>
          </div>
          {error && <div style={{ color: "red" }}>{error}</div>}{" "}
        </Box>
      </Container>

      <Modal
        title="HTML Output"
        open={isModalVisible}
        onOk={handleModalOk}
        onCancel={handleModalCancel}
        footer={[
          <Button key="back" onClick={handleModalCancel}>
            Close
          </Button>,
        ]}
        width={1100}
      >
        <div
          style={{
            width: "100%",
            height: "calc(100vh - 300px)",
            border: "1px solid #dddddd",
            overflowY: "scroll",
          }}
          data-portal-editer="Quill"
          className="portal-Quill"
        >
          <div dangerouslySetInnerHTML={{ __html: editorContent }} />
        </div>
      </Modal>
    </div>
  );
};

export default QuillEditor;
