import React from "react";
import { Container, Box } from "@lib/Layout";
const RecentHistory = ({ children }) => {
  return (
    <Container
      alignBox="column"
      className="max-w-96 min-w-96 rounded-md"
      style={{ boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px" }}
    >
      {children}
    </Container>
  );
};

export default RecentHistory;
