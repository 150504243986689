import React from "react";
import { useNavigate } from "react-router-dom";
import NotFoundEmpty from "../../images/notFound.jpg";

const NotFound = () => {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate("/");
  };

  return (
    <section className="bg-white flex justify-center items-center h-full">
      <div className="py-8 px-4 mx-auto max-w-screen-xl lg:py-16 lg:px-6">
        <div className="mx-auto max-w-screen-sm text-center">
          <h1 className=" text-3xl tracking-tight font-extrabold text-slate-700">
            Page Not Found.
          </h1>

          <div className="flex justify-center items-center">
            <img
              loading="lazy"
              src={NotFoundEmpty}
              alt="page not found"
              className="w-6/12"
            />
          </div>
          <button
            type="button"
            onClick={handleClick}
            className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800"
          >
            Back to Home
          </button>
        </div>
      </div>
    </section>
  );
};

export default NotFound;
