import React from "react";
import { Container, Box } from "@lib/Layout";
import QuillEditor from "../QuillEditor/QuillEditor";

const CreateTemplate = () => {
  return (
    <Container className="bg-sky-50 p-2">
      <QuillEditor />
    </Container>
  );
};

export default CreateTemplate;
