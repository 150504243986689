import { useState } from "react";

const useExecuteRule = () => {
  const [loadingRule, setLoadingRule] = useState(false);
  const [errorRule, setErrorRule] = useState(null);
  const [dataRule, setDataRule] = useState(null);

  const [loadingLiveRule, setLoadingLiveRule] = useState(false);
  const [errorLiveRule, setErrorLiveRule] = useState(null);
  const [dataLiveRule, setDataLiveRule] = useState(null);

  const [loadingGetRule, setLoadingGetRule] = useState(false);
  const [errorGetRule, setErrorGetRule] = useState(null);
  const [dataGetRule, setDataGetRule] = useState(null);

  const loginData = JSON.parse(localStorage.getItem("logindata"));
  const Token = loginData ? loginData.accessToken : null;
  const userId = loginData ? loginData.userId : "";
  console.log("Token", Token);

  const executeRule = async (ruleId) => {
    setLoadingRule(true);
    setErrorRule(null);
    setDataRule(null);
    try {
      const response = await fetch(
        "https://usfarmdata.org/fastapi/email_function/?operation=executerule",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${Token}`,
          },
          body: JSON.stringify({ RuleId: ruleId }),
        }
      );

      if (!response.ok) throw new Error("Failed to fetch executerule data");
      const result = await response.json();
      setDataRule(result);
    } catch (err) {
      setErrorRule(err.message);
    } finally {
      setLoadingRule(false);
    }
  };

  const executeLiveRule = async (ruleCriteria) => {
    setLoadingLiveRule(true);
    setErrorLiveRule(null);
    setDataLiveRule(null);
    console.log(ruleCriteria, "ruleCriteria");
    try {
      const response = await fetch(
        "https://usfarmdata.org/fastapi/email_function/?operation=executeliverule",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${Token}`,
          },
          body: JSON.stringify({ RuleCriteria: ruleCriteria }),
        }
      );

      if (!response.ok) throw new Error("Failed to fetch executeliverule data");
      const result = await response.json();
      setDataLiveRule(result);
    } catch (err) {
      setErrorLiveRule(err.message);
    } finally {
      setLoadingLiveRule(false);
    }
  };

  const getRule = async (inputUserId) => {
    setLoadingGetRule(true);
    setErrorGetRule(null);
    setDataGetRule(null);
    try {
      const response = await fetch(
        "https://usfarmdata.org/fastapi/email_function/?operation=getrule",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${Token}`,
          },
          body: JSON.stringify({ UserId: inputUserId }),
        }
      );

      if (!response.ok) throw new Error("Failed to fetch getrule data");
      const result = await response.json();
      console.log(result, "result");
      setDataGetRule(result);
    } catch (err) {
      setErrorGetRule(err.message);
    } finally {
      setLoadingGetRule(false);
    }
  };

  return {
    dataRule,
    loadingRule,
    errorRule,
    executeRule,
    dataLiveRule,
    loadingLiveRule,
    errorLiveRule,
    executeLiveRule,
    dataGetRule,
    loadingGetRule,
    errorGetRule,
    getRule,
  };
};

export default useExecuteRule;
