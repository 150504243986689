import { useState, useEffect } from "react";

const useFetchRuleById = (RuleId) => {
  const [rule, setRule] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const fetchRule = async () => {
    const loginData = JSON.parse(localStorage.getItem("logindata"));
    const token = loginData ? loginData.accessToken : null;
    const UserId = loginData ? loginData.userId : null;

    setLoading(true);
    setError(null);

    try {
      const response = await fetch(
        "https://usfarmdata.org/fastapi/email_function/?operation=getrulebyid",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({ UserId: UserId, RuleId: RuleId }),
        }
      );

      if (!response.ok) {
        throw new Error(`Error: ${response.status} - ${response.statusText}`);
      }

      const data = await response.json();
      console.log(data, "rule Data");
      setRule(data);
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (RuleId) {
      fetchRule();
    }
  }, [RuleId]);

  return { rule, loading, error };
};

export default useFetchRuleById;
