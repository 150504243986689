import React, { useState } from "react";
import { useFontSize } from "../../context/FontSizeContext";
import { Tooltip } from "antd"; // Import Tooltip from Ant Design

const FontSizeDropdown = () => {
  const {
    fontSize,
    increaseFontSize,
    decreaseFontSize,
    resetFontSize,
    isDefaultSize,
  } = useFontSize();

  const isMinSize = fontSize <= 1;
  const isMaxSize = fontSize >= 1.25;

  return (
    <div className="relative inline-block text-left">
      <div className="p-4 ">
        <div className="flex items-center rounded-md">
          <div className="text-slate-600 mr-3">Font Size </div>
          {/* Tooltip for Decrease button */}
          <Tooltip title={isMinSize ? "Min size reached" : "Decrease"}>
            <button
              onClick={decreaseFontSize}
              className={`px-3 py-1 border-r  ${
                isMinSize
                  ? "bg-gray-200 text-gray-400 cursor-not-allowed"
                  : "bg-gray-300 text-gray-700 hover:bg-gray-400 active:bg-gray-500 font-semibold"
              }`}
              disabled={isMinSize}
              style={{
                borderTopLeftRadius: "4px",
                borderBottomLeftRadius: "4px",
              }}
            >
              -
            </button>
          </Tooltip>

          {/* Tooltip for Increase button */}
          <Tooltip title={isMaxSize ? "Max size reached" : "Increase"}>
            <button
              onClick={increaseFontSize}
              className={`px-3 py-1 ${
                isMaxSize
                  ? "bg-gray-200 text-gray-400 cursor-not-allowed"
                  : "bg-gray-300 text-gray-700 hover:bg-gray-400 active:bg-gray-500 font-semibold"
              }`}
              disabled={isMaxSize}
              style={{
                borderTopRightRadius: "4px",
                borderBottomRightRadius: "4px",
              }}
            >
              +
            </button>
          </Tooltip>

          {!isDefaultSize && (
            <span
              onClick={resetFontSize}
              className="text-sm text-blue-500 hover:text-blue-700 active:text-blue-800 rounded-md cursor-pointer px-2"
            >
              Reset
            </span>
          )}
        </div>
      </div>
    </div>
  );
};

export default FontSizeDropdown;
