import React, { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useRoles } from "../../../context/RoleContext";
import { Container, Box } from "@lib/Layout";
import { Button } from "antd";

const EditUser = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { user } = location.state || {};

  const { roles } = useRoles();
  const loginData = JSON.parse(localStorage.getItem("logindata"));
  const adminId = loginData ? loginData.adminId : null;

  const [formData, setFormData] = useState({
    userid: user?.userid || "",
    firstname: user?.firstname || "",
    lastname: user?.lastname || "",
    emailid: user?.emailid || "",
    phone: user?.phone || "",
    roleid: user?.roleid,
    rolename: user?.rolename,
    loginname: user?.loginname || "",
    adminid: adminId,
    
    
  });

  console.log(formData);

  const [errors, setErrors] = useState({});

  const validateName = (name) => /^[A-Za-z\s]+$/.test(name);
  const validatePhone = (phone) =>
    /^\(?([0-9]{3})\)?[-.●]?([0-9]{3})[-.●]?([0-9]{4})$/.test(phone);
  const validateEmail = (email) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    let newErrors = { ...errors };

    if (name === "firstname" || name === "lastname") {
      if (!validateName(value)) {
        newErrors[name] = "Name should contain only letters and spaces.";
      } else {
        delete newErrors[name];
      }
    }

    if (name === "phone") {
      if (!validatePhone(value)) {
        newErrors[name] = "Invalid phone number format.";
      } else {
        delete newErrors[name];
      }
    }

    if (name === "emailid") {
      if (!validateEmail(value)) {
        newErrors[name] = "Invalid email address.";
      } else {
        delete newErrors[name];
      }
    }

    // Handle role selection
    if (name === "rolename") {
      const selectedRole = roles.find(
        (role) => role.roleid === parseInt(value, 10)
      );
      setFormData((prevData) => ({
        ...prevData,
        roleid: selectedRole?.roleid || 0,
        rolename: selectedRole?.rolename || "",
      }));
    } else {
      setFormData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }

    setErrors(newErrors);
  };

  const handleSave = async () => {
    const token = localStorage.getItem("token");

    try {
      const response = await fetch(
        "https://usfarmdata.org/fastapi/user_function/?operation=edituser",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(formData),
        }
      );

      console.log(formData);

      if (response.ok) {
        alert("User information updated successfully");
        navigate("/listuser");
        console.log(formData);
      } else {
        console.error("Failed to update user:", response.statusText);
      }
    } catch (error) {
      console.error("Error updating user:", error);
    }
  };

  const handleBack = () => {
    navigate("/listuser");
  };

  return (
    <div className="min-h-full items-center bg-sky-50 p-8">
      <Container className="bg-white shadow-md rounded-md">
        <Box className="p-3 border-b text-lg font-semibold">
          Edit User Information
        </Box>
        <Box>
          <div className="flex flex-wrap gap-3 p-5 w-9/12">
            <div className="mb-3 relative w-80">
              <label className="block text-gray-700 mb-1">First Name</label>
              <input
                type="text"
                className="w-full p-2 border rounded-md"
                name="firstname"
                value={formData.firstname}
                onChange={handleInputChange}
              />
              {errors.firstname && (
                <p className="text-red-500 text-xs mt-1">{errors.firstname}</p>
              )}
            </div>
            <div className="mb-3 relative w-80">
              <label className="block text-gray-700 mb-1">Last Name</label>
              <input
                type="text"
                className="w-full p-2 border rounded-md"
                name="lastname"
                value={formData.lastname}
                onChange={handleInputChange}
              />
              {errors.lastname && (
                <p className="text-red-500 text-xs mt-1">{errors.lastname}</p>
              )}
            </div>
            <div className="mb-3 relative w-80">
              <label className="block text-gray-700 mb-1">Phone</label>
              <input
                type="text"
                className="w-full p-2 border rounded-md"
                name="phone"
                value={formData.phone}
                onChange={handleInputChange}
              />
              {errors.phone && (
                <p className="text-red-500 text-xs mt-1">{errors.phone}</p>
              )}
            </div>
            <div className="mb-3 relative w-80">
              <label className="block text-gray-700 mb-1">Email</label>
              <input
                type="text"
                className="w-full p-2 border rounded-md"
                name="emailid"
                value={formData.emailid}
                onChange={handleInputChange}
              />
              {errors.emailid && (
                <p className="text-red-500 text-xs mt-1">{errors.emailid}</p>
              )}
            </div>
            <div className="mb-3 relative w-80">
              <label className="block text-gray-700 mb-1">Role</label>
              <select
                name="rolename"
                className="w-full p-2 border rounded-md"
                value={formData.roleid || ""}
                onChange={handleInputChange}
              >
                {/* Display the user's current role as the initial option */}
                {formData.rolename && (
                  <option value={formData.roleid}>{formData.rolename}</option>
                )}
                {roles
                  .filter((role) => role.roleid !== formData.roleid) // Remove duplicate role
                  .map((role) => (
                    <option key={role.roleid} value={role.roleid}>
                      {role.rolename}
                    </option>
                  ))}
              </select>
            </div>

            <div className="mb-3 relative w-80">
              <label className="block text-gray-700 mb-1">User Name</label>
              <input
                type="text"
                className="w-full p-2 border rounded-md"
                name="loginname"
                value={formData.loginname}
                onChange={handleInputChange}
              />
            </div>
          </div>
        </Box>
        <Box className="flex p-3 border-t gap-2">
          <Button onClick={handleBack}>Cancel</Button>
          <Button type="primary" onClick={handleSave}>
            Save
          </Button>
        </Box>
      </Container>
    </div>
  );
};

export default EditUser;
