import { useState } from "react";
import { message } from "antd";

const useUpdateSchedule = () => {
  const [editScheduleLoading, setEditScheduleLoading] = useState(false);

  const updateSchedule = async (editScheduleData) => {
    setEditScheduleLoading(true);

    try {
      const loginData = JSON.parse(localStorage.getItem("logindata"));
      const token = loginData ? loginData.accessToken : null;

      const response = await fetch(
        "https://usfarmdata.org/fastapi/email_function/?operation=editschedule",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({
            Templateid: editScheduleData.Templateid,
            Ruleid: editScheduleData.Ruleid,
            Schedulename: editScheduleData.Schedulename,
            Scheduledatetime: editScheduleData.Scheduledatetime,
            CreatedUserId: editScheduleData.CreatedUserId,
            Fromemail: editScheduleData.Fromemail,
            Scheduleid: editScheduleData.Scheduleid,
            EmailSubject: editScheduleData.EmailSubject,
            FromName: editScheduleData.FromName,
          }),
        }
      );

      if (!response.ok) {
        throw new Error(`Error: ${response.status}`);
      }

      const result = await response.json();
      message.success("Schedule Updated successfully!");
      localStorage.removeItem("EditScheduledData");
      return result;
    } catch (err) {
      message.error(`Failed to add schedule: ${err.message}`);
    } finally {
      setEditScheduleLoading(false);
    }
  };

  return { updateSchedule, editScheduleLoading };
};

export default useUpdateSchedule;
