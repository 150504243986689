import React from "react";
import "./App.css";
import AppContainer from "./AppContainer";
import { LoginProvider } from "./context/LoginContext";
import { RolesProvider } from "./context/RoleContext";
import { FontSizeProvider } from "./context/FontSizeContext";
import { WebSocketProvider } from "./context/WebSocketContext";
import GmailProvider from "./context/GmailContext";
function App() {
  return (
    <LoginProvider>
      <FontSizeProvider>
        <WebSocketProvider>
          <div className="h-screen">
            <RolesProvider>
              <GmailProvider>
                <AppContainer />
              </GmailProvider>
            </RolesProvider>
          </div>
        </WebSocketProvider>
      </FontSizeProvider>
    </LoginProvider>
  );
}

export default App;
