import { Select, message } from "antd";

const statusOptions = [
  { label: "Active", value: "active", color: "green" },
  { label: "On Hold", value: "suspended", color: "orange" },
  // { label: "Completed", value: "completed", color: "blue" },
  // { label: "Inactive", value: "inactive", color: "red" },
];

const ScheduleColumns = (setRefresh) => [
  {
    title: "Schedule Name",
    dataIndex: "Schedulename",
    key: "Schedulename",
    width: "20%",
    needSorting: false,
    needSearch: true,
  },
  {
    title: "Email Subject",
    dataIndex: "EmailSubject",
    key: "EmailSubject",
    width: "20%",
  },
  {
    title: "From Email",
    dataIndex: "Fromemail",
    key: "Fromemail",
    width: "20%",
  },
  {
    title: "From Name",
    dataIndex: "FromName",
    key: "FromName",
    width: "20%",
  },
  {
    title: "Status",
    dataIndex: "Status",
    key: "schdulestatus",
    width: "20%",
    render: (status, record) => {
      const selectedOption =
        statusOptions.find((opt) => opt.value === status.toLowerCase()) || {};
      const statusColor = selectedOption.color || "defaultColor";

      const isDisabled = status.toLowerCase() === "inactive";

      return (
        <Select
          defaultValue={status.toLowerCase()}
          style={{
            width: "100%",
          }}
          options={statusOptions.map((option) => ({
            value: option.value,
            label: <span style={{ color: option.color }}>{option.label}</span>,
          }))}
          onChange={(newStatus) =>
            handleStatusChange(record.Scheduleid, newStatus, setRefresh)
          }
          disabled={isDisabled}
        />
      );
    },
  },
  {
    title: "Created Date",
    dataIndex: "Createddate",
    key: "Createddate",
    width: "20%",
    sorter: (a, b) => new Date(a.Createddate) - new Date(b.Createddate),
    render: (date) => new Date(date).toLocaleDateString(),
    sortDirections: ["descend", "ascend"],
    needSorting: true,
  },
  {
    title: "Schedule Date Time",
    dataIndex: "Scheduledatetime",
    key: "Scheduledatetime",
    width: "20%",
    sorter: (a, b) =>
      new Date(a.Scheduledatetime) - new Date(b.Scheduledatetime),
    render: (date) => new Date(date).toLocaleString(),
    sortDirections: ["descend", "ascend"],
    needSorting: true,
  },
];

const handleStatusChange = async (scheduleId, newStatus, setRefresh) => {
  try {
    const loginData = JSON.parse(localStorage.getItem("logindata"));
    const token = loginData?.accessToken;

    const response = await fetch(
      `https://usfarmdata.org/fastapi/email_function/?operation=updateschedule`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          Scheduleid: scheduleId,
          Status: newStatus,
        }),
      }
    );

    if (!response.ok) {
      throw new Error("Failed to update schedule status");
    }

    message.success("Schedule status updated successfully.");
    setRefresh((prev) => !prev);
  } catch (error) {
    console.error("Error updating schedule status:", error);
    message.error("Failed to update schedule status.");
  }
};

export default ScheduleColumns;
