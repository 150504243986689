import React, { createContext, useContext, useEffect, useState } from "react";

const LoginContext = createContext();

export const LoginProvider = ({ children }) => {
  const [user, setUser] = useState(null);

  const login = (response) => {
    const userData = {
      accessToken: response.access_token,
      adminId: response.adminid,
      createdDate: response.createddate,
      email: response.emailid,
      firstName: response.firstname,
      lastName: response.lastname,
      lastSignInDate: response.lastsignindate,
      loginFromGoogle: response.loginfromgoogle,
      phone: response.phone,
      roleName: response.roleName,
      uniqueId: response.uniqueid,
      userId: response.userid,
    };

    const loginTime = new Date().getTime();
    localStorage.setItem("loginTime", loginTime);
    localStorage.setItem("logindata", JSON.stringify(userData));

    setUser(userData);
    scheduleLogout(loginTime);
  };

  const logout = () => {
    setUser(null);
    localStorage.removeItem("logindata");
    localStorage.removeItem("token");
    localStorage.removeItem("loginTime");
    window.location.reload();
  };

  const isTokenExpired = () => {
    const loginTime = localStorage.getItem("loginTime");
    if (loginTime) {
      const currentTime = new Date().getTime();
      const timeDifference = currentTime - parseInt(loginTime, 10);
      const hoursElapsed = timeDifference / (1000 * 60 * 60);
      return hoursElapsed >= 24;
    }
    return true;
  };

  const scheduleLogout = (loginTime) => {
    const currentTime = new Date().getTime();
    const timeElapsed = currentTime - loginTime;
    const timeLeft = 24 * 60 * 60 * 1000 - timeElapsed;

    setTimeout(logout, timeLeft);
  };

  useEffect(() => {
    const storedUser = localStorage.getItem("logindata");
    const loginTime = localStorage.getItem("loginTime");

    if (storedUser && loginTime) {
      if (isTokenExpired()) {
        logout();
      } else {
        setUser(JSON.parse(storedUser));
        scheduleLogout(parseInt(loginTime, 10));
      }
    }
  }, []);

  return (
    <LoginContext.Provider value={{ user, login, logout }}>
      {children}
    </LoginContext.Provider>
  );
};

export const useLogin = () => useContext(LoginContext);
