import React from "react";
import { Tooltip } from "antd";
import SqlCodePreview from "@components/SqlCodePreview/SqlCodePreview";

const RuleColumns = [
  {
    title: "Rule Name",
    dataIndex: "RuleName",
    key: "Rulename",
    width: "20%",
    needSorting: true,
  },
  {
    title: "Counts",
    dataIndex: "Counts",
    key: "Counts",
    width: "20%",
    render: (counts) => {
      return (
        <span className="bg-blue-100 text-blue-800 text-xs font-medium  px-2.5 py-0.5 rounded  border border-blue-400">
          {counts}
        </span>
      );
    },
  },
  {
    title: "Rule Criteria",
    dataIndex: "RuleCriteria",
    key: "Rulecriteria",
    width: "30%",
    ellipsis: true,
    render: (sqlString) => {
      return (
        <Tooltip
          title={<SqlCodePreview sqlString={sqlString} />}
          // placement="topLeft"
          overlayStyle={{ maxWidth: "400px" }}
        >
          <span
            style={{
              color: "rgb(59 130 246)",
              cursor: "pointer",
              border: "1px solid rgb(59 130 246)",
              borderRadius: "4px",
              padding: "2px 5px",
            }}
          >
            View Rule Criteria
          </span>
        </Tooltip>
      );
    },
  },
  {
    title: "Status",
    dataIndex: "Status",
    key: "rulestatus",
    width: "20%",
    render: (status) => {
      let color = "";
      let statusText = "";

      switch (status.toUpperCase()) {
        case "S":
          color = "rgb(22 163 74)";
          statusText = "Active";
          break;
        case "D":
          color = "lightcoral";
          statusText = "Inactive";
          break;
        case "G":
          color = "orange";
          statusText = "Suspended";
          break;
        case "C":
          color = "blue";
          statusText = "Completed";
          break;
        default:
          color = "gray";
          statusText = "Unknown";
      }

      return (
        <span
          style={{
            color,
            border: `1px solid ${color}`,
            padding: "2px 8px",
            borderRadius: "4px",
            lineHeight: "16px",
            display: "inline-block",
          }}
        >
          {statusText}
        </span>
      );
    },
  },
  {
    title: "Created Date",
    dataIndex: "CreatedDate",
    key: "Createddate",
    width: "20%",
    sorter: (a, b) => new Date(a.Createddate) - new Date(b.Createddate),
    render: (date) => new Date(date).toLocaleDateString(),
    sortDirections: ["descend", "ascend"],
    needSorting: true,
  },
];

export default RuleColumns;
