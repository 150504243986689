import React, { useMemo, useRef, useState, useEffect } from "react";
import { Container, Box } from "@lib/Layout";
import { useNavigate } from "react-router-dom";
import CustomTable from "@components/CustomTable/CustomTable";
import {
  Table,
  DatePicker,
  Modal,
  Select,
  Spin,
  Empty,
  Button,
  message,
} from "antd";
import Chip from "@mui/material/Chip";
import Tooltip from "@mui/material/Tooltip";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import ContactMailIcon from "@mui/icons-material/ContactMail";
import CampaignIcon from "@mui/icons-material/Campaign";
import DeleteIcon from "@mui/icons-material/Delete";
import CancelScheduleSendIcon from "@mui/icons-material/CancelScheduleSend";
import InfoIcon from "@mui/icons-material/Info";
import "./ListCenter.css";
import debounce from "lodash.debounce";
import Loading from "@components/Loading/Loading";
import axios from "axios";

const ListCenter = () => {
  const navigate = useNavigate();
  const { RangePicker } = DatePicker;
  const [messageApi, contextHolder] = message.useMessage();
  const dateFormat = "MM-DD-YYYY";
  const [chipLNvalues, setLNValue] = useState({
    lncriteria: "",
    lncriterias: [],
  });
  const [chipLCvalues, setLCValue] = useState({
    lccriteria: "",
    lccriterias: [],
  });
  const [chipLTvalues, setLTValue] = useState({
    ltcriteria: "",
    ltcriterias: [],
  });
  const [chipDatevalues, setDateValue] = useState({
    datecriteria: "",
    datecriterias: [],
  });
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [loading, setLoading] = useState(false);
  const [txtlistname, setListtName] = useState("");
  const [txtlistcount, setListtCount] = useState("");
  const [txtlisttype, setListtType] = useState("");
  const [selectedListNames, setSelectedListNames] = useState("");
  const [selectedListIDs, setSelectedListIDs] = useState("");
  const [apiTblData, setapiTblData] = useState(null);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [selectuservalue, setSelectUserValue] = useState([]);
  const [deleteListID, setDeleteListID] = useState("");
  const [unshareListID, setUnshareListID] = useState("");
  const [trigger, setTrigger] = useState(false); // Trigger state to rerun useEffect
  const callFunction = useRef(() => {}); // Ref to hold the function

  const ViewContactNavigate = (item) => {
    const { listid, sharedlistflag, liststatus, listname } = item;
    const currentList = {
      listid,
      sharedlistflag,
      liststatus,
      listname,
    };
    localStorage.setItem("currentlist", JSON.stringify(currentList));
    navigate("/contacts");
  };

  const [logindata, setLoginData] = useState(() => {
    const savedLoginData = localStorage.getItem("logindata");
    return savedLoginData ? JSON.parse(savedLoginData) : null;
  });

  function DebounceSelect({ fetchOptions, debounceTimeout = 800, ...props }) {
    const [fetching, setFetching] = useState(false);
    const [options, setOptions] = useState([]);
    const fetchRef = useRef(0);
    const debounceFetcher = useMemo(() => {
      const loadOptions = (value) => {
        fetchRef.current += 1;
        const fetchId = fetchRef.current;
        setOptions([]);
        setFetching(true);
        fetchOptions(value).then((newOptions) => {
          if (fetchId !== fetchRef.current) {
            // for fetch callback order
            return;
          }
          setOptions(newOptions);
          setFetching(false);
        });
      };
      return debounce(loadOptions, debounceTimeout);
    }, [fetchOptions, debounceTimeout]);
    return (
      <Select
        labelInValue
        filterOption={false}
        onSearch={debounceFetcher}
        notFoundContent={fetching ? <Spin size="small" /> : null}
        {...props}
        options={options}
      />
    );
  }

  callFunction.fetchAPIdata = async () => {
    try {
      setLoading(true);
      const logindata = JSON.parse(localStorage.getItem("logindata"));
      const apiuserID = logindata?.userId;
      const apiToken = logindata?.accessToken;

      // Fetch local JSON data
      const response = await fetch(
        "https://usfarmdata.org/fastapi/list_function/?operation=get",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${apiToken}`,
          },
          body: JSON.stringify({
            userid: apiuserID,
            listname: "",
            listcount: "",
            listtype: "",
            listdatefrom: "",
            listdateto: "",
            shareduserid: "",
            mergedlistflag: "",
          }),
        }
      );

      if (response.ok) {
        const data = await response.json();

        const formattedData = data.map((item) => {
          let sharedContent = "";
          let cancelListshare = "";
          if (item.sharedlistflag) {
            if (apiuserID === item.userid) {
              sharedContent = <PrimarySharedList />;
              cancelListshare = (
                <CancelScheduleSendIcon
                  className="tblunshareicon"
                  fontSize="small"
                  onClick={() => showUSLModal(item.listid)}
                ></CancelScheduleSendIcon>
              );
            } else {
              sharedContent = <SecondarySharedList />;
            }
          }

          return {
            key: item.listid,
            userid: item.userid,
            isshared: item.sharedlistflag ? 1 : 0,
            ismerged: item.mergedlistflag ? 1 : 0,
            shared: sharedContent,
            listcount: item.listcount,
            shareduserids: item.shareduserids,
            listname: `${item.listname} (${item.listcount})`,
            listdate: new Date(item.listdate).toLocaleDateString("en-US", {
              year: "numeric",
              month: "2-digit",
              day: "2-digit",
            }),
            viewlist: (
              <Tooltip title="View this list">
                <RemoveRedEyeIcon
                  className="tblviewicon"
                  fontSize="small"
                  onClick={() => {
                    ViewContactNavigate(item);
                  }}
                />
              </Tooltip>
            ),
            removelist:
              apiuserID === item.userid ? (
                <Tooltip title="Delete this list">
                  <DeleteIcon
                    className="tbldeleteicon"
                    fontSize="small"
                    onClick={() => showDLModal(item.listid)}
                  ></DeleteIcon>
                </Tooltip>
              ) : (
                ""
              ),
            cancelshare: cancelListshare,
            deploypostcard: (
              <ContactMailIcon
                className="tbldeployicon"
                fontSize="small"
              ></ContactMailIcon>
            ),
            campaign: (
              <CampaignIcon
                className="tblcampaignicon"
                fontSize="small"
              ></CampaignIcon>
            ),
          };
        });

        setapiTblData(formattedData);
      } else {
        const errorText = await response.text();
        console.log("Response body:", errorText);
        setapiTblData(null);
      }
    } catch (error) {
      console.error("Error Details:", {
        message: error.message,
        config: error.config,
        code: error.code,
        stack: error.stack,
      });
      setapiTblData(null);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    // Trigger the function when the page loads (component mounts)
    setTrigger(true);
  }, []); // Empty dependency array ensures this runs only once when the component mounts

  useEffect(() => {
    if (trigger) {
      callFunction.fetchAPIdata();
      setTrigger(false);
    }
  }, [trigger]);

  //chips for filter
  const handleDateChange = (range) => {
    if (range) {
      const valueOfInput1 = range[0].format("MM-DD-YYYY");
      const valueOfInput2 = range[1].format("MM-DD-YYYY");

      setDateValue({
        datecriterias: [valueOfInput1 + " to " + valueOfInput2],
        datecriteria: "",
      });
    }
  };
  const handleLNChipChange = (name) => (event) => {
    setLNValue({ ...chipLNvalues, [name]: event.target.value });
    setListtName(event.target.value);
  };
  const handleLCChipChange = (name) => (event) => {
    setLCValue({ ...chipLCvalues, [name]: event.target.value });
    setListtCount(event.target.value);
  };
  const handleLTChipChange = (event) => {
    setLTValue({
      ltcriterias: [event.target.value],
      ltcriteria: "",
    });
    setListtType(event.target.value);
  };

  function LNpushToarray(e) {
    if (e.key === "Enter") {
      e.preventDefault();
      setLNValue({
        lncriterias: [...chipLNvalues.lncriterias, e.target.value],
        lncriteria: "",
      });
      setListtName("");
    }
  }
  function LCpushToarray(e) {
    if (e.key === "Enter") {
      e.preventDefault();
      setLCValue({
        lccriterias: [...chipLCvalues.lccriterias, e.target.value],
        lccriteria: "",
      });
      setListtCount("");
    }
  }

  const handleLNChipDelete = (item) => {
    var rm = chipLNvalues.lncriterias.indexOf(item);
    chipLNvalues.lncriterias.splice(rm, 1);
    setLNValue({ lncriterias: chipLNvalues.lncriterias });
  };
  const handleLCChipDelete = (item) => {
    var rm = chipLCvalues.lccriterias.indexOf(item);
    chipLCvalues.lccriterias.splice(rm, 1);
    setLCValue({ lccriterias: chipLCvalues.lccriterias });
  };
  const handleLTChipDelete = (item) => {
    var rm = chipLTvalues.ltcriterias.indexOf(item);
    chipLTvalues.ltcriterias.splice(rm, 1);
    setLTValue({ ltcriterias: chipLTvalues.ltcriterias });
  };
  const handleDateChipDelete = (item) => {
    var rm = chipDatevalues.datecriterias.indexOf(item);
    chipDatevalues.datecriterias.splice(rm, 1);
    setDateValue({ datecriterias: chipDatevalues.datecriterias });
  };
  //chips for filter

  //apply and clear filter
  const handleClearFilter = (e) => {
    setLNValue({ lncriteria: "", lncriterias: [] });
    setLCValue({ lccriteria: "", lccriterias: [] });
    setLTValue({ ltcriteria: "", ltcriterias: [] });
    setDateValue({ datecriteria: "", datecriterias: [] });
    setTrigger(true);
  };

  const handleApplyFilter = async (e) => {
    var listNames;
    var listCounts;
    var listType;
    var listTypeParam;
    var listDates;
    var listDateFrom;
    var listDateTo;
    var apiPARAMS;
    var userID;
    var apiToken;
    if (chipLNvalues.lncriterias) {
      listNames = chipLNvalues.lncriterias.map((item, i) => item).join(",");
    }

    if (chipLCvalues.lccriterias) {
      listCounts = chipLCvalues.lccriterias.map((item, i) => item).join(",");
    }

    if (chipLTvalues.ltcriterias) {
      listType = chipLTvalues.ltcriterias.map((item, i) => item).join(",");
      if (listType === "All Lists") {
        listTypeParam = "A";
      } else if (listType === "Shared Lists") {
        listTypeParam = "S";
      } else if (listType === "My Lists") {
        listTypeParam = "M";
      } else {
        listTypeParam = "";
      }
    }

    if (chipDatevalues.datecriterias) {
      listDates = chipDatevalues.datecriterias.map((item, i) => item).join(",");
      const dates_array = listDates.split(" to ");
      if (dates_array.length === 2) {
        listDateFrom = dates_array[0];
        listDateTo = dates_array[1];
      } else {
        listDateFrom = "";
        listDateTo = "";
      }
    }

    try {
      setLoading(true);
      apiToken = logindata?.accessToken;
      userID = logindata?.userId;

      const response = await fetch(
        "https://usfarmdata.org/fastapi/list_function/?operation=get",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${apiToken}`,
          },
          body: JSON.stringify({
            listname: listNames,
            listcount: listCounts,
            listtype: listTypeParam,
            listdatefrom: listDateFrom,
            listdateto: listDateTo,
            userid: userID,
            shareduserid: "",
            mergedlistflag: "",
          }),
        }
      );

      if (response.ok) {
        const data = await response.json();

        const formattedData = data.map((item) => {
          let sharedContent = "";
          let cancelListshare = "";
          if (item.sharedlistflag) {
            if (userID === item.userid) {
              sharedContent = <PrimarySharedList />;
              cancelListshare = (
                <CancelScheduleSendIcon
                  className="tblunshareicon"
                  fontSize="small"
                  onClick={() => showUSLModal(item.listid)}
                ></CancelScheduleSendIcon>
              );
            } else {
              sharedContent = <SecondarySharedList />;
            }
          }

          return {
            key: item.listid,
            userid: item.userid,
            isshared: item.sharedlistflag ? 1 : 0,
            ismerged: item.mergedlistflag ? 1 : 0,
            shared: sharedContent,
            listcount: item.listcount,
            shareduserids: item.shareduserids,
            listname: `${item.listname} (${item.listcount})`,
            listdate: new Date(item.listdate).toLocaleDateString("en-US", {
              year: "numeric",
              month: "2-digit",
              day: "2-digit",
            }),
            viewlist: (
              <Tooltip title="View this list">
                <RemoveRedEyeIcon
                  className="tblviewicon"
                  fontSize="small"
                  onClick={() => ViewContactNavigate(item.listid)}
                ></RemoveRedEyeIcon>
              </Tooltip>
            ),
            removelist:
              userID === item.userid ? (
                <Tooltip title="Delete this list">
                  <DeleteIcon
                    className="tbldeleteicon"
                    fontSize="small"
                    onClick={() => showDLModal(item.listid)}
                  ></DeleteIcon>
                </Tooltip>
              ) : (
                ""
              ),
            cancelshare: cancelListshare,
            deploypostcard: (
              <ContactMailIcon
                className="tbldeployicon"
                fontSize="small"
              ></ContactMailIcon>
            ),
            campaign: (
              <CampaignIcon
                className="tblcampaignicon"
                fontSize="small"
              ></CampaignIcon>
            ),
          };
        });

        setapiTblData(formattedData);
      } else {
        const errorText = await response.text();
        console.log("Response body:", errorText);
        setapiTblData(null);
      }
    } catch (error) {
      console.log(error);
      setapiTblData(null);
    } finally {
      setLoading(false);
    }
  };
  //apply and clear filter

  //table functions
  const onSelectChange = (newSelectedRowKeys, newSelectedRows) => {
    setSelectedRowKeys(newSelectedRowKeys);
    setSelectedRows(newSelectedRows);

    if (newSelectedRows.length > 0) {
      const sListNames = newSelectedRows.reduce(
        (arrval, item) =>
          arrval ? `${arrval}, ${item.listname}` : `${item.listname}`,
        null
      );

      const sListIDs = newSelectedRows.reduce(
        (arrvalkeys, item) =>
          arrvalkeys ? `${arrvalkeys}, ${item.key}` : `${item.key}`,
        null
      );

      setSelectedListIDs(sListIDs);
      setSelectedListNames(sListNames);
      setSelectUserValue(null);
    }
  };

  const handleGetCheckboxProps = (record) => {
    if (record.isshared) {
      return {
        disabled: true,
      };
    }
  };

  const locale = {
    emptyText: <Empty description="No Data"></Empty>,
  };

  const rowSelection = {
    selectedRowKeys,
    selectedRows,
    onChange: onSelectChange,
  };

  const hasShareSelected = selectedRowKeys.length >= 1;
  const hasMergeSelected = selectedRowKeys.length >= 2;

  const columns = [
    {
      title: "",
      dataIndex: "shared",
      width: 50,
      fixed: "left",
      needSorting: false,
    },
    {
      title: "List Name",
      dataIndex: "listname",
      width: 300,
      fixed: "left",
      sorter: (a, b) => a.listname.length - b.listname.length,
      sortDirections: ["descend", "ascend"],
    },
    {
      title: "Create Date",
      dataIndex: "listdate",
      width: 200,
      sorter: (a, b) => new Date(a.listdate) - new Date(b.listdate),
      sortDirections: ["descend", "ascend"],
    },
    {
      title: "Deploy Postcard",
      dataIndex: "deploypostcard",
      width: 200,
      align: "center",
      needSorting: false,
    },
    {
      title: "Marketing Campaign",
      dataIndex: "campaign",
      width: 200,
      align: "center",
      needSorting: false,
    },
    {
      title: "Unshare List",
      dataIndex: "cancelshare",
      width: 150,
      align: "center",
      needSorting: false,
    },
    {
      title: "viewlist",
      dataIndex: "viewlist",
      align: "center",
      width: 100,
      needSorting: false,
    },
    {
      title: "Delete",
      dataIndex: "removelist",
      align: "center",
      fixed: "right",
      width: 100,
      needSorting: false,
    },
  ];

  const PrimarySharedList = () => {
    return (
      <div className="flex items-center gap-1">
        <div className="rounded-full h-5 w-5 bg-green-600 text-white">
          <span className="flex items-center justify-center text-sm">S</span>
        </div>
      </div>
    );
  };

  const SecondarySharedList = () => {
    return (
      <div className="flex items-center gap-1">
        <div className="rounded-full h-5 w-5 bg-red-600 text-white">
          <Tooltip title="Shared List">
            <span className="flex items-center justify-center text-sm">S</span>
          </Tooltip>
        </div>
      </div>
    );
  };
  //table functions

  //share list
  const [isSLModalOpen, setIsSLModalOpen] = useState(false);
  const showSLModal = () => {
    setErrorMessage("");
    setConfirmLoading(false);
    setIsSLModalOpen(true);
  };
  const handleSLOk = async () => {
    setErrorMessage("");
    var sUserstoShare = "";
    try {
      setConfirmLoading(true);
      const apiToken = logindata?.accessToken;

      if (selectuservalue && selectuservalue.length > 0) {
        sUserstoShare = selectuservalue.reduce(
          (arrval, item) =>
            arrval ? `${arrval},${item.value}` : `${item.value}`,
          null
        );
      } else {
        throw new Error("Please select atleast one user to share.");
      }

      const response = await fetch(
        "https://usfarmdata.org/fastapi/list_function/?operation=share",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            accept: "application/json",
            Authorization: `Bearer ${apiToken}`,
          },
          body: JSON.stringify({
            listids: selectedListIDs,
            user_ids_to_share: sUserstoShare,
          }),
        }
      );

      if (response.ok) {
        const result = await response.json();
        setTrigger(true);
        setIsSLModalOpen(false);
        setErrorMessage("");
        messageApi.open({
          type: "success",
          content: "List shared successfully.",
          className: "custom-message",
        });
      } else {
        const errorText = await response.text();
        console.log(errorText);
        throw new Error("Server Error, please try again later.");
      }
    } catch (error) {
      setErrorMessage(error.message);
      setConfirmLoading(false);
    }
  };
  const handleSLCancel = () => {
    setErrorMessage("");
    setSelectedRowKeys([]);
    setConfirmLoading(false);
    setIsSLModalOpen(false);
  };
  //share list

  //merge list
  const [isMLModalOpen, setIsMLModalOpen] = useState(false);
  const showMLModal = () => {
    setIsMLModalOpen(true);
  };
  const handleMLOk = () => {
    setIsMLModalOpen(false);
  };
  const handleMLCancel = () => {
    setIsMLModalOpen(false);
  };
  //merge list

  //delete list
  const [isDLModalOpen, setIsDLModalOpen] = useState(false);
  const showDLModal = (dellistid) => {
    setDeleteListID(dellistid);
    setConfirmLoading(false);
    setIsDLModalOpen(true);
  };
  const handleDLOk = async () => {
    try {
      if (deleteListID !== "") {
        setConfirmLoading(true);
        const apiToken = logindata?.accessToken;
        const response = await fetch(
          "https://usfarmdata.org/fastapi/list_function/?operation=delete",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              accept: "application/json",
              Authorization: `Bearer ${apiToken}`,
            },
            body: JSON.stringify({
              listid: deleteListID,
            }),
          }
        );

        if (response.ok) {
          const result = await response.json();
          messageApi.open({
            type: "warning",
            content: "List deleted successfully.",
            className: "custom-message",
          });
        } else {
          const errorText = await response.text();
          console.log(errorText);
          throw new Error("Server Error, please try again later.");
        }
      }
    } catch (error) {
      messageApi.open({
        type: "error",
        content: "Server Error, please try again later.",
        className: "custom-message",
      });
    } finally {
      setTrigger(true);
      setDeleteListID("");
      setConfirmLoading(false);
      setIsDLModalOpen(false);
      setErrorMessage("");
    }
  };
  const handleDLCancel = () => {
    setDeleteListID("");
    setConfirmLoading(false);
    setIsDLModalOpen(false);
  };
  //delete list

  //unshare list
  const [isUSLModalOpen, setIsUSLModalOpen] = useState(false);
  const showUSLModal = (usllistid) => {
    setUnshareListID(usllistid);
    setConfirmLoading(false);
    setIsUSLModalOpen(true);
  };
  const handleUSLOk = async () => {
    try {
      console.log(unshareListID);
      if (unshareListID !== "") {
        setConfirmLoading(true);
        const apiToken = logindata?.accessToken;
        const response = await fetch(
          "https://usfarmdata.org/fastapi/list_function/?operation=unshare",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              accept: "application/json",
              Authorization: `Bearer ${apiToken}`,
            },
            body: JSON.stringify({
              listids: unshareListID,
            }),
          }
        );
        if (response.ok) {
          const result = await response.json();
          messageApi.open({
            type: "success",
            content: "Selected List unshared from other users.",
            className: "custom-message",
          });
        } else {
          const errorText = await response.text();
          console.log(errorText);
          throw new Error("Server Error, please try again later.");
        }
      }
    } catch (error) {
      messageApi.open({
        type: "error",
        content: "Server Error, please try again later.",
        className: "custom-message",
      });
    } finally {
      setTrigger(true);
      setUnshareListID("");
      setConfirmLoading(false);
      setIsUSLModalOpen(false);
      setErrorMessage("");
    }
  };
  const handleUSLCancel = () => {
    setUnshareListID("");
    setConfirmLoading(false);
    setIsUSLModalOpen(false);
  };
  //unshare list

  //fetusers for dropdown
  async function fetchUserList(username) {
    try {
      if (username === "") {
        return [];
      }

      const response = await fetch(
        "https://usfarmdata.org/fastapi/user_function/?operation=getactiveuser",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${logindata?.accessToken}`,
          },
          body: JSON.stringify({
            adminid: logindata?.adminId,
            searchtext: username,
          }),
        }
      );

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const body = await response.json();
      return body.users.map((user) => ({
        label: `${user.username}`,
        value: user.userid,
      }));
    } catch (error) {
      console.error("Error fetching user list:", error);
      // Return a default value or handle the error as needed
      return [];
    }
  }
  //fetusers for dropdown

  return (
    <Container
      alignBox="column"
      className="bg-sky-50 gap-3 py-5 justify-center items-center"
    >
      {contextHolder} {/* Required to display the message */}
      <Box className="w-4/5 bg-white rounded-lg shadow-md p-4">
        <div className="flex flex-col gap-5 p-2">
          <div className="grid">
            <div className="flex justify-start pl-4 pt-2 text-lg">Filters</div>
          </div>

          <div className="grid gap-6 md:grid-cols-4 pl-4">
            <div>
              <input
                type="text"
                name="txtlistname"
                value={txtlistname}
                onChange={handleLNChipChange("lncriteria")}
                onKeyDown={(e) => LNpushToarray(e)}
                className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                placeholder="List Name"
              />
            </div>
            <div>
              <input
                type="text"
                id="txtlistcount"
                value={txtlistcount}
                onChange={handleLCChipChange("lccriteria")}
                onKeyDown={(e) => LCpushToarray(e)}
                className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                placeholder="List Count"
              />
            </div>
            <div>
              <select
                id="txtlisttype"
                placeholder="List Type"
                value={txtlisttype}
                onChange={handleLTChipChange}
                class="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
              >
                <option value="Shared Lists">Shared Lists</option>
                <option value="My Lists">My Lists</option>
                <option value="All Lists">All Lists</option>
              </select>
            </div>
            <div>
              <RangePicker
                className="border border-gray-300 text-gray-900 text-sm rounded-lg p-2"
                format={dateFormat}
                id={{
                  start: "listfromdate",
                  end: "listtodate",
                }}
                allowClear="true"
                onChange={handleDateChange}
              />
            </div>
          </div>

          <div className="flex flex-wrap flex-row grow gap-3 pl-4">
            {chipLNvalues.lncriterias &&
              chipLNvalues.lncriterias.map((lncriteria, id) => (
                <Chip
                  key={id}
                  label={lncriteria}
                  onDelete={() => handleLNChipDelete(lncriteria)}
                />
              ))}
            {chipLCvalues.lccriterias &&
              chipLCvalues.lccriterias.map((lccriteria, id) => (
                <Chip
                  key={id}
                  label={lccriteria}
                  onDelete={() => handleLCChipDelete(lccriteria)}
                />
              ))}
            {chipLTvalues.ltcriterias &&
              chipLTvalues.ltcriterias.map((ltcriteria, id) => (
                <Chip
                  key={id}
                  label={ltcriteria}
                  onDelete={() => handleLTChipDelete(ltcriteria)}
                />
              ))}
            {chipDatevalues.datecriterias &&
              chipDatevalues.datecriterias.map((datecriteria, id) => (
                <Chip
                  key={id}
                  label={datecriteria}
                  onDelete={() => handleDateChipDelete(datecriteria)}
                />
              ))}
          </div>

          <div className="flex flex-row grow justify-end gap-5 items-center mr-6">
            <a
              href="#"
              onClick={handleClearFilter}
              className="text-base font-medium text-blue-600 dark:text-blue-500 underline"
            >
              Clear All
            </a>

            <Button
              type="primary"
              onClick={handleApplyFilter}
              icon={<FilterAltIcon fontSize="small"></FilterAltIcon>}
            >
              Apply Filter
            </Button>
          </div>
        </div>
      </Box>
      <Box
        className="bg-white w-4/5 rounded-lg shadow-md"
        flexible={loading ? true : false}
      >
        {loading ? (
          <div className="flex justify-center items-center h-full">
            <Loading />
          </div>
        ) : (
          <Container>
            <Box className="p-3">
              <Container align="vertical" alignBox="row" isCover={false}>
                <Box flexible className=" text-lg ">
                  List Center
                </Box>
                <Box>
                  <div className="flex gap-2 items-center">
                    <Button
                      type="primary"
                      onClick={showSLModal}
                      disabled={!hasShareSelected}
                    >
                      Share Lists
                    </Button>
                    <Button
                      type="primary"
                      onClick={showMLModal}
                      disabled={!hasMergeSelected}
                    >
                      Merge
                    </Button>
                  </div>
                </Box>
              </Container>
            </Box>
            <Box>
              <CustomTable
                onSelectChange={onSelectChange}
                columns={columns}
                data={apiTblData}
                needActionColumn={false}
                heightOffset={420}
                getCheckboxProps={handleGetCheckboxProps}
              />
            </Box>
          </Container>
        )}
      </Box>
      <Modal
        title="Share Lists"
        okText="Share"
        open={isSLModalOpen}
        onOk={handleSLOk}
        confirmLoading={confirmLoading}
        onCancel={handleSLCancel}
      >
        <div className="flex flex-row basis-full border-t">
          <div class="w-full gap-4 p-2">
            <div className="mb-6">
              <label
                for="txtSLSelectedList"
                className="block mb-2 text-sm font-medium text-gray-900"
              >
                Selected List(s)
              </label>
              <textarea
                id="txtSLSelectedList"
                rows="4"
                name="txtSLSelectedList"
                value={selectedListNames}
                class="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 border-b border border-gray-300 focus:ring-blue-500 focus:border-blue-500"
                disabled
                readonly
              ></textarea>
            </div>
            <div>
              <label
                for="txtSLSelectedUsertoShare"
                className="block mb-2 text-sm font-medium text-gray-900"
              >
                Add User(s) to selected list(s)
              </label>
              <DebounceSelect
                id="txtSLSelectedUsertoShare"
                name="txtSLSelectedUsertoShare"
                mode="multiple"
                value={selectuservalue}
                allowClear
                style={{
                  width: "100%",
                }}
                placeholder="Search Users to share"
                fetchOptions={fetchUserList}
                onChange={(newValue) => {
                  setSelectUserValue(newValue);
                }}
                className="border-gray-300 text-gray-900 text-sm focus:ring-blue-500 focus:border-blue-500 block w-full"
              />
            </div>
            <div className="text-sm">
              {errorMessage && (
                <span className="text-red-600">{errorMessage}</span>
              )}
            </div>
          </div>
        </div>
      </Modal>
      <Modal
        title="Merge Lists"
        okText="Save"
        open={isMLModalOpen}
        onOk={handleMLOk}
        onCancel={handleMLCancel}
      >
        <div className="flex flex-row basis-full border-t">
          <div class="w-full gap-4 p-2">
            <div className="mb-6">
              <label
                for="txtMLSelectedList"
                className="block mb-2 text-sm font-medium text-gray-900"
              >
                Selected Lists
              </label>
              <textarea
                id="txtMLSelectedList"
                rows="4"
                name="txtMLSelectedList"
                value={selectedListNames}
                class="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 border-b border border-gray-300 focus:ring-blue-500 focus:border-blue-500"
                disabled
                readonly
              ></textarea>
            </div>
            <div className="mb-6">
              <label
                for="txtMLListName"
                className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
              >
                ListName
              </label>
              <input
                type="text"
                id="txtMLListName"
                name="txtMLListName"
                required
                placeholder="Type Listname to Merge"
                className="border-b border-gray-300 text-gray-900 text-sm focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
              ></input>
            </div>
            <div>
              <p className="text-sm mergeHelpText">
                <InfoIcon fontSize="small"></InfoIcon> After the merge, the
                original lists will no longer appear in your list management
                area, and this cannot be reverted. Are you sure?
              </p>
            </div>
          </div>
        </div>
      </Modal>
      <Modal
        title="Delete List"
        okText="Delete"
        open={isDLModalOpen}
        onOk={handleDLOk}
        onCancel={handleDLCancel}
        confirmLoading={confirmLoading}
      >
        <p>Are you sure you want delete this list?</p>
      </Modal>
      <Modal
        title="Unshare The List"
        okText="Unshare"
        open={isUSLModalOpen}
        onOk={handleUSLOk}
        onCancel={handleUSLCancel}
        confirmLoading={confirmLoading}
      >
        <p>Are you sure you want unshare this list from users?</p>
      </Modal>
    </Container>
  );
};

export default ListCenter;
