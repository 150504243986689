import React, { useState } from "react";
import { Container, Box } from "@lib/Layout";
import { Tooltip } from "antd";
import HeaderField from "../common/HeaderField/HeaderField";
import Button from "@components/Button/Button";
import SocialIcon from "../common/SocialIcon/SocialIcon";
import BookmarkButton from "../common/BookMarkButton/BookmarkButton";
import StarOutlineIcon from "@mui/icons-material/StarOutline";
import styled from "@emotion/styled";
import AddIcon from "@mui/icons-material/Add";
import { useWindowWidth } from "../../../context/WindowContext";

const StarOutlineIconSize = styled(StarOutlineIcon)`
  font-size: 1.2rem;
  vertical-align: text-top;
`;

const ContactSubHeader = ({
  title,
  handleNote,
  companyName,
  Phone,
  Email,
  Adders = "91 Bruce St, Littleton, MA 01460",
  isloading,
  contactId,
  isEditing,
}) => {
  const [activeIndex, setActiveIndex] = useState(null);
  const windowWidth = useWindowWidth();

  const handleClick = (index) => {
    setActiveIndex(index);
  };

  const items = [
    { icon: <StarOutlineIconSize />, text: "Hot", color: "red" },
    { icon: <StarOutlineIconSize />, text: "Warm", color: "green" },
    { icon: <StarOutlineIconSize />, text: "Fraud", color: "skyblue" },
    { icon: <StarOutlineIconSize />, text: "Cold", color: "#bebe0b" },
  ];
  return (
    <Container
      alignBox={windowWidth > 600 ? "row" : "column"}
      className="rounded bg-gray-100"
    >
      {isloading ? (
        <div style={{ height: "103px", width: "100%" }}>
          <Container alignBox="row" align="vertical" className="py-3 px-5">
            <Box flexible>
              <div className="h-2.5 bg-gray-300 rounded-full dark:bg-gray-600 w-52 mb-2.5"></div>
              <div className="w-32 h-2 bg-gray-200 rounded-full dark:bg-gray-700 mb-2.5"></div>
              <div className="w-32 h-2 bg-gray-200 rounded-full dark:bg-gray-700"></div>
            </Box>
            <Box>
              <div className="flex flex-col">
                <div className="h-2.5 bg-gray-300 rounded-full dark:bg-gray-700 w-32 mb-2.5"></div>
                <div className="h-2.5 bg-gray-300 rounded-full dark:bg-gray-700 w-24 mb-2.5"></div>
                <div className="h-2.5 bg-gray-300 rounded-full dark:bg-gray-700 w-24"></div>
              </div>
            </Box>
          </Container>
        </div>
      ) : (
        <>
          <Box flexible className="py-3 px-5">
            <div className="flex flex-col gap-2">
              <div
                className="font-segoe text-lg font-semibold antialiased"
                style={{ color: "#3A3A3A" }}
              >
                <div className="flex items-center gap-2">
                  <div>{title}</div>
                  <Tooltip title="ContactId">
                    <span className="bg-indigo-100 text-indigo-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded border border-indigo-400">
                      {contactId}
                    </span>
                  </Tooltip>

                  {isEditing && (
                    <div>
                      <div class="flex justify-center">
                        <span class="relative flex h-3	w-3">
                          <span class="absolute inline-flex h-full w-full animate-ping rounded-full bg-pink-600 opacity-75"></span>
                          <span class="relative inline-flex h-3	w-3	 rounded-full bg-pink-500"></span>
                        </span>
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <div>
                <div className="flex flex-row items-center flex-wrap gap-10">
                  <HeaderField label="Company" discription={companyName} />
                  <HeaderField label="Phone" discription={Phone} />
                  <HeaderField label="Email" discription={Email} />
                  <HeaderField label="Address" discription={Adders} />
                </div>
              </div>
            </div>
          </Box>
          <Box className="px-3 py-1">
            <div className="flex flex-col gap-2 h-full py-1">
              <div className="grow">
                <ul
                  className="flex flex-row rounded-full px-3 py-1 bg-white"
                  style={{ boxShadow: " rgba(0, 0, 0, 0.1) 0px 4px 12px" }}
                >
                  {items.map((item, index) => (
                    <BookmarkButton
                      key={index}
                      index={index}
                      isActive={activeIndex === index}
                      onClick={handleClick}
                      icon={item.icon}
                      text={item.text}
                      defaultColor={item.color}
                    />
                  ))}
                </ul>
              </div>
              <div>
                <div className="flex items-center">
                  <div className="grow">
                    <Button className="rounded-full" onClick={handleNote}>
                      <div className="flex flex-row items-center ">
                        <AddIcon />
                        <span>Add Note</span>
                      </div>
                    </Button>
                  </div>
                  <div>
                    <div className="flex flex-row-reverse items-center gap-2 ">
                      <SocialIcon>
                        <Tooltip title="Facebook">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="20"
                            height="20"
                            viewBox="0 0 71 72"
                            fill="none"
                          >
                            <path
                              d="M46.4233 38.6403L47.7279 30.3588H39.6917V24.9759C39.6917 22.7114 40.8137 20.4987 44.4013 20.4987H48.1063V13.4465C45.9486 13.1020 43.7685 12.9168 41.5834 12.8901C34.9692 12.8901 30.651 16.8626 30.651 24.0442V30.3588H23.3193V38.6403H30.651V58.671H39.6917V38.6403H46.4233Z"
                              fill="#111827"
                            />
                          </svg>
                        </Tooltip>
                      </SocialIcon>
                      <SocialIcon>
                        <Tooltip title="Linkedin">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="20"
                            height="20"
                            viewBox="0 0 72 72"
                            fill="none"
                          >
                            <path
                              d="M24.7612 55.999V20.3354H20.5433V55.999H24.7621H24.7612ZM20.2042 24.5591C23.3679 24.5591 25.3687 22.4348 25.3687 19.7801C25.3086 17.065 23.3679 20 20.2203 20C17.0605 20 20 17.065 20 19.7799C20 22.4346 17.0001 24.5588 20.0938 24.5588H20.2034L20.2042 24.5591ZM29.8633 55.999H39.0805V40.5521C39.0805 39.7264 39.1406 38.8985 39.3841 38.3088C40.0502 36.6562 41.5668 34.9455 44.1138 34.9455C47.4484 34.9455 48.7831 37.4821 48.7831 41.2014V55.999H58V40.1376C58 31.6408 53.4532 27.6869 47.3887 27.6869C42.4167 27.6869 40.233 30.4589 39.0198 32.347H39.0812V20.3364H29.8638C29.9841 30.9316 29.8631 56 29.8631 56L29.8633 55.999Z"
                              fill="#111827"
                            />
                          </svg>
                        </Tooltip>
                      </SocialIcon>
                      <SocialIcon>
                        <Tooltip title="Twiter">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="20"
                            height="20"
                            viewBox="0 0 72 72"
                            fill="none"
                          >
                            <path
                              d="M40.7568 32.1716L59.3704 11H54.9596L38.7974 29.383L25.8887 11H11L30.5205 38.7983L11 61H20.4111L32.4788 41.5869L46.1113 61H61L40.7557 32.1716H40.7568ZM34.7202 39.0433L32.7374 36.2752L17.0005 14.2492H23.7756L36.4755 32.0249L38.4533 34.7929L54.9617 57.8986H48.1865L34.7202 39.0443V39.0433Z"
                              fill="#111827"
                            />
                          </svg>
                        </Tooltip>
                      </SocialIcon>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Box>
        </>
      )}
    </Container>
  );
};

export default ContactSubHeader;
