import React, { createContext, useContext, useEffect, useState } from "react";

const WebSocketContext = createContext();
export const useWebSocket = () => useContext(WebSocketContext);

export const WebSocketProvider = ({ children }) => {
  const [latestEvent, setLatestEvent] = useState(null);

  useEffect(() => {
    // Set default event data in localStorage if not already set
    const defaultEventData = {
      event: "phone.callee_ringing",
      call_id: "7426831347805140566",
      caller: "+14023707525",
      callee: "+917708754564",
      status: "ringing",
      duration: 0,
      has_recording: false,
      end_time: null,
    };

    const existingData = localStorage.getItem("ZoomCallEvent");
    if (!existingData) {
      localStorage.setItem("ZoomCallEvent", JSON.stringify(defaultEventData));
      setLatestEvent(defaultEventData);
    }

    const eventSource = new EventSource(
      "https://usfarmdata.org/zoom-fastapi/events"
    );

    eventSource.onmessage = (event) => {
      console.log("New Event", event);
      console.log("Raw Event Data", event.data);

      const callLogData = formatEventData(event.data);
      console.log("callLogData", callLogData);
      setLatestEvent(callLogData);
      localStorage.setItem("ZoomCallEvent", JSON.stringify(callLogData));

      console.log("Stored entire event data:", event.data);
    };

    eventSource.onerror = (error) => {
      console.error("EventSource failed:", error);
    };

    return () => {
      eventSource.close();
    };
  }, []);

  const formatEventData = (data) => {
    const cleanedData = data.replace(/'/g, '"');
    try {
      const parsedData = JSON.parse(cleanedData);
      return {
        event: parsedData.event || undefined,
        call_id: parsedData.call_id || undefined,
        caller: parsedData.caller || undefined,
        callee: parsedData.callee || undefined,
        status: parsedData.status || undefined,
        duration: parsedData.duration || undefined,
        has_recording: parsedData.has_recording || undefined,
        end_time: parsedData.end_time || undefined,
      };
    } catch (error) {
      console.error("Error parsing event data:", error);
      return {
        event: undefined,
        call_id: undefined,
        caller: undefined,
        callee: undefined,
        status: undefined,
        duration: undefined,
        has_recording: undefined,
        end_time: undefined,
      }; // Return default values on error
    }
  };

  return (
    <WebSocketContext.Provider value={{ latestEvent }}>
      {children}
    </WebSocketContext.Provider>
  );
};
