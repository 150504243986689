const updateContactApi = async (contactId, data) => {
  const logindata = JSON.parse(localStorage.getItem("logindata"));
  const apiToken = logindata?.accessToken;

  console.log("API Token:", apiToken);
  if (!apiToken || !contactId) {
    console.error("Missing API token or contactId");
    throw new Error("Missing API token or contactId");
  }

  // Ensure data is an object before spreading
  if (typeof data !== "object" || data === null) {
    throw new Error("Invalid data format. Expected an object.");
  }

  try {
    const requestBody = {
      contactid: contactId,
      ...data, // Spread the data object directly into the request body
    };

    console.log("Request Payload:", requestBody);

    const response = await fetch(
      "https://usfarmdata.org/fastapi/contact_function/?operation=update",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${apiToken}`,
        },
        body: JSON.stringify(requestBody), // Send the request body as JSON
      }
    );

    console.log("Response Status:", response.status);
    console.log("Response Headers:", response.headers);

    let responseData;
    try {
      responseData = await response.json();
      console.log("Response Data:", responseData);
    } catch (parseError) {
      console.error("Failed to parse response as JSON:", parseError);
      responseData = null;
    }

    if (!response.ok) {
      const errorMessage = responseData?.detail || "An error occurred";

      if (response.status === 400) {
        console.error(`Bad Request: ${errorMessage}`);
        throw new Error(`Bad Request: ${errorMessage}`);
      } else if (response.status === 500) {
        console.error(`Server Error: ${errorMessage}`);
        throw new Error(`Server Error: ${errorMessage}`);
      } else {
        console.error(`Error: ${errorMessage}`);
        throw new Error(errorMessage);
      }
    }

    return responseData;
  } catch (error) {
    console.error("Error in updateContactApi:", error);
    throw error;
  }
};

export default updateContactApi;
